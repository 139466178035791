/**
 * Labstep
 */
/**
 * Labstep
 *
 */

import { Molecule } from 'labstep-web/models/molecule.model';
import MENU_COMMANDS_ELEMENTS from 'labstep-web/prosemirror/components/Menu/Commands/elements';
import { reactPropsKey } from 'labstep-web/prosemirror/extensions/external-comm';
import { loaderKey } from 'labstep-web/prosemirror/extensions/loader/plugin';
import { IProseMirrorCommandsElement } from 'labstep-web/prosemirror/extensions/slash/types';
import { IStateDispatchProps } from 'labstep-web/prosemirror/marks/types';
import { replaceWithNode } from 'labstep-web/prosemirror/nodes/commands';
import { getToken } from 'labstep-web/prosemirror/utils/selection';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import { generateTableData } from 'labstep-web/services/table.service';
import { createEntity } from 'labstep-web/state/actions/entity';
import store from 'labstep-web/state/store';
import { IOptions } from 'labstep-web/typings';
import { EditorState } from 'prosemirror-state';
import { v4 } from 'uuid';

export class ProsemirrorSlashService {
  static TRIGGER = '/';

  static addPlaceholder(
    state: EditorState,
    dispatch: IStateDispatchProps['dispatch'],
    id: unknown,
  ): void {
    dispatch?.(
      state.tr.setMeta(loaderKey, {
        add: { id, pos: state.tr.selection.from },
      }),
    );
  }

  static removePlaceholder(
    state: EditorState,
    dispatch: IStateDispatchProps['dispatch'],
    id: unknown,
  ): void {
    dispatch?.(
      state.tr.setMeta(loaderKey, {
        remove: { id },
      }),
    );
  }

  /** Set placeholder and replace token with node */
  static updateViewOnCreate(
    state: EditorState,
    dispatch: IStateDispatchProps['dispatch'],
    key: string,
    addContent?: boolean,
  ): IOptions {
    const id = {};
    ProsemirrorSlashService.addPlaceholder(state, dispatch, id);

    const { from, to } = getToken(
      state,
      ProsemirrorSlashService.TRIGGER,
    );
    return {
      onSuccess: ({ response }): void => {
        const result = Array.isArray(response.result)
          ? response.result[0]
          : response.result;

        const attrs = {
          [getIdAttribute(key)]: result,
        };

        ProsemirrorSlashService.removePlaceholder(
          state,
          dispatch,
          id,
        );
        replaceWithNode(
          state,
          dispatch,
          from,
          to,
          key,
          attrs,
          addContent && state.schema.nodes.paragraph.createAndFill(),
        );
      },
      onFail: (): void =>
        ProsemirrorSlashService.removePlaceholder(
          state,
          dispatch,
          id,
        ),
    };
  }

  static createElement(
    state: EditorState,
    dispatch: IStateDispatchProps['dispatch'],
    element: IProseMirrorCommandsElement,
  ): void {
    const { key, entityName, onCreate } = MENU_COMMANDS_ELEMENTS.find(
      (el) => el.id === element,
    )!;

    if (onCreate) {
      onCreate(state, dispatch);
      return;
    }

    const reactPropsPlugin = state.plugins.find(
      (p) => p.spec.key === reactPropsKey,
    );

    if (!reactPropsPlugin || !entityName) {
      return;
    }

    let { entity } = reactPropsPlugin.getState(state);

    let body = {};
    let options: Record<string, unknown> = {
      additionalMeta: { redirect: true },
    };
    let addContent = false;
    if (element === 'protocol_table') {
      body = { name: 'Untitled Table', data: generateTableData() };
    } else if (['protocol_step'].includes(element)) {
      body = [{}];
      options = { ...options, batch: true };
      addContent = true;
    } else if (element === 'metadata') {
      body = { type: 'file', is_output: true };
      entity = entity.metadata_thread;
    } else if (element === 'molecule') {
      body = Molecule.createBodyDefault;
    }

    const additionalOptions =
      ProsemirrorSlashService.updateViewOnCreate(
        state,
        dispatch,
        key,
        addContent,
      );

    store.dispatch(
      createEntity(
        entityName,
        body,
        entity.entityName,
        entity.id,
        v4(),
        {
          ...options,
          ...additionalOptions,
        },
      ),
    );
  }
}

/**
 * Labstep
 *
 *
 * @desc Modal showing empy state for premium features
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import PremiumFeatureModal from 'labstep-web/core/PremiumFeature/Modal';
import React from 'react';
import { IPremiumFeatureGuardProps } from './types';

export const PremiumFeatureGuard: React.FC<
  IPremiumFeatureGuardProps
> = ({ premiumFeature, component, authenticatedUserEntity }) => {
  if (authenticatedUserEntity.hasFeatureEnabled(premiumFeature)) {
    return component({});
  }

  return (
    <PremiumFeatureModal
      viewComponent={component}
      premiumFeature={premiumFeature}
    />
  );
};

export default withAuthenticatedUserEntity(PremiumFeatureGuard);

// eslint-disable-next-line no-useless-escape
const MATCH_MENTION_REGEX = /(@\[[^\]]+?\]\([^\)]+?\))/;

/** Same as above but also groups display and id */
// eslint-disable-next-line no-useless-escape
const MATCH_MENTION_REGEX_WITH_GROUPS = /@\[([^\]]+?)\]\(([^\)]+?)\)/;

export const extractParts = (text: string) => {
  const reg = MATCH_MENTION_REGEX;
  const parts = text.split(reg);
  return parts;
};

type RenderPartType = React.FC<{ name: string; id: string }>;

export const printBody = (
  body: string,
  renderPart: RenderPartType,
) => {
  return extractParts(body).map((part) => {
    const reg = MATCH_MENTION_REGEX_WITH_GROUPS;
    const match = part.match(reg);
    return match
      ? renderPart({ name: match[1], id: match[2] })
      : part;
  });
};

import React from 'react';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import styles from './styles.module.scss';
import { IPremiumFeatureLockIconProps } from './types';

export const PremiumFeatureLockIcon: React.FC<
  IPremiumFeatureLockIconProps
> = ({ premiumFeature }) =>
  useHasFeatureFlagEnabled(premiumFeature) ? null : (
    <div className={styles.container}>
      <img src="/img/icon/lock.svg" alt="lock icon" />
    </div>
  );

export default PremiumFeatureLockIcon;

/**
 * Labstep.
 */

import Joi from '@hapi/joi';

export interface EnvConfig {
  [key: string]: string;
}

const fullString = Joi.string();
const nullableString = Joi.string().allow('', null);

const validateInput = (env: NodeJS.ProcessEnv): EnvConfig => {
  const objectSchema: Joi.ObjectSchema = Joi.object({
    COMMIT_HASH: nullableString,
    NODE_ENV: Joi.string().valid('development', 'production', 'test'),
    REACT_APP_BOX_DOMAIN_NAME: nullableString,
    REACT_APP_BUGSNAG_API_KEY_WEB: nullableString,
    REACT_APP_BUILD_TIMESTAMP: nullableString,
    REACT_APP_IMAGE_RESIZE_DOMAIN_NAME: nullableString,
    REACT_APP_JUPYTER_DOMAIN_NAME: nullableString,
    REACT_APP_JUPYTER_OAUTH_AUTHORIZE: nullableString,
    REACT_APP_JUPYTER_URL: nullableString,
    REACT_APP_JWT_PUBLIC_KEY: nullableString,
    REACT_APP_LABSTEP_API_URL: fullString,
    REACT_APP_LABSTEP_ENV: Joi.string().valid(
      'local',
      'ci',
      'root',
      'staging',
      'production',
    ),
    REACT_APP_WEB_AG_GRID_LICENSE_KEY: nullableString,
    REACT_APP_WEB_FULL_CALENDAR_LICENSE_KEY: nullableString,
    REACT_APP_WEB_GOOGLE_CLIENT_ID: nullableString,
    REACT_APP_WEB_GOOGLE_DEVELOPER_KEY: nullableString,
    REACT_APP_WEB_INTERCOM_APP_ID: nullableString,
    REACT_APP_WEB_LOGROCKET_APP_ID: nullableString,
    REACT_APP_WEB_ONE_DRIVE_CLIENT_ID: nullableString,
    REACT_APP_WEB_SPREADJS_LICENSE_KEY: nullableString,
    REACT_APP_WEB_SPREADJS_DESIGNER_LICENSE_KEY: nullableString,
    REACT_APP_WEB_STRIPE_API_KEY: nullableString,
    REACT_APP_WEBSOCKET_URL: nullableString,
    REACT_APP_REACT_REDUX_LOGGER: nullableString,
  });

  const { error, value } = objectSchema.validate(env, {
    allowUnknown: true,
  });
  if (error) {
    throw new Error(`Config validation error: ${error.message}`);
  }

  return value;
};

export class ConfigService {
  private readonly envConfig: EnvConfig;

  public constructor(env: NodeJS.ProcessEnv) {
    this.envConfig = validateInput(env);

    if (
      this.labstepEnv === 'production' ||
      this.labstepEnv === 'staging'
    ) {
      // eslint-disable-next-line no-console
      console.log(
        'BUILD_TIMESTAMP',
        this.envConfig.REACT_APP_BUILD_TIMESTAMP,
      );
    }
  }

  public get commitHash(): string {
    return String(this.envConfig.COMMIT_HASH);
  }

  public get nodeEnv(): string {
    return String(this.envConfig.NODE_ENV);
  }

  public get boxUrl(): string {
    return `https://${String(
      this.envConfig.REACT_APP_BOX_DOMAIN_NAME,
    )}`;
  }

  public get bugsnagApiKey(): string {
    return String(this.envConfig.REACT_APP_BUGSNAG_API_KEY_WEB);
  }

  public get imageResizeUrl(): string {
    return `https://${String(
      this.envConfig.REACT_APP_IMAGE_RESIZE_DOMAIN_NAME,
    )}`;
  }

  public get jupyterLambdaUrl(): string {
    let protocol = 'https';
    if (this.labstepEnv === 'local') {
      protocol = 'http';
    }
    return `${protocol}://${String(
      this.envConfig.REACT_APP_JUPYTER_DOMAIN_NAME,
    )}`;
  }

  public get jupyterUrl(): string {
    return String(this.envConfig.REACT_APP_JUPYTER_URL);
  }

  public get jwtPublicKey(): string {
    return atob(String(this.envConfig.REACT_APP_JWT_PUBLIC_KEY));
  }

  public get labstepApiUrl(): string {
    return String(this.envConfig.REACT_APP_LABSTEP_API_URL);
  }

  public get labstepEnv(): string {
    const labstepEnv = String(this.envConfig.REACT_APP_LABSTEP_ENV);
    if (labstepEnv === 'root') {
      return 'production';
    }
    return labstepEnv;
  }

  public get agGridLicenseKey(): string {
    return String(this.envConfig.REACT_APP_WEB_AG_GRID_LICENSE_KEY);
  }

  public get fullCalendarLicenseKey(): string {
    return String(
      this.envConfig.REACT_APP_WEB_FULL_CALENDAR_LICENSE_KEY,
    );
  }

  public get googleClientId(): string {
    return String(this.envConfig.REACT_APP_WEB_GOOGLE_CLIENT_ID);
  }

  public get googleDeveloperKey(): string {
    return String(this.envConfig.REACT_APP_WEB_GOOGLE_DEVELOPER_KEY);
  }

  public get intercomAppId(): string {
    return String(this.envConfig.REACT_APP_WEB_INTERCOM_APP_ID);
  }

  public get logrocketAppId(): string {
    return String(this.envConfig.REACT_APP_WEB_LOGROCKET_APP_ID);
  }

  public get oneDriveClientId(): string {
    return String(this.envConfig.REACT_APP_WEB_ONE_DRIVE_CLIENT_ID);
  }

  public get spreadJSLicenseKey(): string {
    return String(this.envConfig.REACT_APP_WEB_SPREADJS_LICENSE_KEY);
  }

  public get spreadJSDesignerLicenseKey(): string {
    return String(
      this.envConfig.REACT_APP_WEB_SPREADJS_DESIGNER_LICENSE_KEY,
    );
  }

  public get stripeApiKey(): string {
    return String(this.envConfig.REACT_APP_WEB_STRIPE_API_KEY);
  }

  public get websocketUrl(): string {
    return String(this.envConfig.REACT_APP_WEBSOCKET_URL);
  }

  public get reactReduxLogger(): boolean {
    return this.envConfig.REACT_APP_REACT_REDUX_LOGGER !== '0';
  }

  public get debug(): boolean {
    return this.labstepEnv === 'local' || this.labstepEnv === 'ci';
  }

  public get mockReactAlert(): boolean {
    return this.labstepEnv === 'local' || this.labstepEnv === 'ci';
  }
}

export const configService = new ConfigService(process.env);

/**
 * Labstep
 *
 *
 * @desc Action to update is enabled to true
 */

import EntityActionUpdate from 'labstep-web/components/Entity/Action/Update';
import React from 'react';
import { IOrganizationSAMLActionUpdateIsEnabledProps } from './types';

const OrganizationSAMLActionUpdateIsEnabled: React.FC<
  IOrganizationSAMLActionUpdateIsEnabledProps
> = ({ organizationSAML }) =>
  !organizationSAML.is_enabled ? (
    <EntityActionUpdate
      entityName={organizationSAML.entityName}
      id={organizationSAML.guid}
      body={{ is_enabled: true }}
      actionComponentProps={{
        type: 'button',
        text: 'Enforce for all users',
        disabled: !organizationSAML.areAllAttributesFilled,
        modalConfirmationProps: {
          positive: true,
          header: 'Enforce SSO for all users',
          message:
            'Are you sure you want to proceed? This will enforce SSO for all the users in your organization. Please make sure that you have tested the setup with at least one user and verified that it works. If you have any questions please contact Labstep support team.',
        },
      }}
    />
  ) : null;

export default OrganizationSAMLActionUpdateIsEnabled;

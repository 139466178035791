/**
 * Labstep
 *
 * @module components/UserOrganization/Action/AddBulkUsers
 * @desc Action to add multiple users to organization
 */

import { EntityCreateContainer } from 'labstep-web/containers/Entity/Create';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalDefault from 'labstep-web/core/Modal/Default';
import { User } from 'labstep-web/models/user.model';
import Button from 'labstep-web/core/Button';
import React from 'react';
import CreateUsers from './CreateUsers';
import styles from './styles.module.scss';
import { UserOrganizationActionAddBulkUsersProps } from './types';

export const UserOrganizationActionAddBulkUsers: React.FC<
  UserOrganizationActionAddBulkUsersProps
> = ({ organization }) => {
  const availableSeats = organization.max_seats - organization.seats;

  const showWarning =
    organization.max_seats !== null && availableSeats <= 0;

  const warningContent = (
    <span>
      You cannot add more users because you have reached the user
      limit for your plan. Please contact{' '}
      <a href="mailto:sales@labstep.com">sales@labstep.com</a> or your
      account manager to add more.
    </span>
  );
  const warningHeader = (
    <span className={styles.header}>User Limit Reached</span>
  );
  let header = 'Add Users';

  if (organization.max_seats) {
    header += ` (${availableSeats} seats remaining)`;
  }

  return (
    <ModalDefault
      size="small"
      header={showWarning ? warningHeader : header}
      content={
        showWarning
          ? warningContent
          : ({ toggleModal }) => (
              <EntityCreateContainer
                entityName={User.entityName}
                batch
              >
                {({ create, status }) => (
                  <CreateUsers
                    toggleModal={toggleModal}
                    create={create}
                    status={status}
                    availableSeats={
                      organization.max_seats
                        ? availableSeats
                        : undefined
                    }
                  />
                )}
              </EntityCreateContainer>
            )
      }
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          onClick={toggleModal}
          type="button"
          text="Add Users"
        />
      )}
      footer={
        showWarning
          ? ({ toggleModal }) => (
              <Button
                className={styles.closeButton}
                onClick={toggleModal}
              >
                Close
              </Button>
            )
          : null
      }
    />
  );
};

export default UserOrganizationActionAddBulkUsers;

/**
 * Labstep
 *
 * @module core/Modal/Confirmation
 * @desc Confirmation dialog
 * Opens a modal to confirm or cancel action
 */

import React from 'react';
import cx from 'classnames';
import { ModalHOC } from 'labstep-web/hoc/Modal';
import Button from 'labstep-web/core/Button';
import Header from 'semantic-ui-react/dist/commonjs/elements/Header';
import Modal from '..';
import styles from './styles.module.scss';
import { IActionsProps, IModalConfirmationProps } from './types';

const Actions: React.FC<IActionsProps> = (props) => {
  const {
    status,
    onConfirm,
    onCancel,
    toggleModal,
    confirmButtonContent,
    cancelButtonContent,
    extraButtons,
    positive,
  } = props;
  const color = positive ? 'blue' : 'red';

  return (
    <div className={styles.actions}>
      {cancelButtonContent && (
        <Button
          basic
          status={status}
          onClick={onCancel || toggleModal}
        >
          {cancelButtonContent}
        </Button>
      )}
      {confirmButtonContent && (
        <Button
          status={status}
          onClick={() => onConfirm?.({ toggleModal })}
          loader
          color={color}
        >
          {confirmButtonContent}
        </Button>
      )}
      {extraButtons}
    </div>
  );
};

const ModalConfirmation: React.FC<IModalConfirmationProps> = (
  props,
) => {
  const {
    children,
    status,
    confirmButtonContent,
    cancelButtonContent,
    message,
    onConfirm,
    onCancel,
    header,
    positive,
    open,
    extraButtons,
    onClose,
    ...rest
  } = props;

  return (
    <ModalHOC
      viewComponent={children}
      modalComponent={({ toggleModal, ...modalProps }) => {
        // TODO: Refactor with redux
        const toggle = () => {
          toggleModal();
        };
        return (
          <Modal
            id="confirmation-modal"
            className={cx({ [styles.warning]: !positive })}
            closeIcon={!(status && status.isFetching)}
            onClose={(evt, data) => {
              if (!(status && status.isFetching)) {
                toggle();
              }
              onClose?.(evt, data);
            }}
            closeOnDimmerClick
            onClick={(evt: MouseEvent) => evt.stopPropagation()}
            {...modalProps}
            {...rest}
            open={
              open !== undefined
                ? open
                : modalProps && modalProps.open
            }
          >
            <Header
              className={cx({ [styles.warningHeader]: !positive })}
            >
              {header}
            </Header>
            <Modal.Content>
              {message}
              <div style={{ textAlign: 'right', marginTop: 30 }}>
                <Actions
                  positive={positive}
                  status={status}
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                  toggleModal={toggle}
                  confirmButtonContent={confirmButtonContent}
                  cancelButtonContent={cancelButtonContent}
                  extraButtons={extraButtons}
                />
              </div>
            </Modal.Content>
          </Modal>
        );
      }}
    />
  );
};

ModalConfirmation.defaultProps = {
  message: 'Are you sure?',
  confirmButtonContent: 'Yes',
  cancelButtonContent: 'Cancel',
};

export default ModalConfirmation;

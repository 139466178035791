/**
 * Labstep
 *
 * @module components/Group/Settings
 * @desc Group settings
 */

import { useHasAccessGroup } from 'labstep-web/components/Entity/Can';
import GroupActionDelete from 'labstep-web/components/Group/Action/Delete';
import GroupActionSetDefaultUserGroupType from 'labstep-web/components/Group/Action/SetDefaultUserGroupType';
import GroupActionSetIsAutoMember from 'labstep-web/components/Group/Action/SetIsAutoMember';
import GroupActionSetIsInviteOnly from 'labstep-web/components/Group/Action/SetIsInviteOnly';
import GroupFormShowEditDescription from 'labstep-web/components/Group/Form/ShowEdit/Description';
import GroupFormShowEditName from 'labstep-web/components/Group/Form/ShowEdit/Name';
import ProfilePicture from 'labstep-web/components/Profile/Picture';
import Card from 'labstep-web/core/Card/Card';
import Flex from 'labstep-web/core/Flex';
import { Size } from 'labstep-web/core/Initials/types';
import HorizontalTable from 'labstep-web/core/Table/Horizontal';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import { IGroupSettingsProps, GroupContentProps } from './types';

const GroupContent: React.FC<GroupContentProps> = ({
  isMobile,
  isGroupOwner,
  rows,
  group,
}) => {
  return (
    <div id="group-settings">
      <HorizontalTable
        padded
        headerProps={{
          width: isMobile ? 7 : 3,
          style: { fontWeight: 500 },
        }}
        rows={rows}
        columns={[group]}
        unstackable
      />
      {isGroupOwner && (
        <>
          {isMobile ? (
            <GroupActionDelete
              actionComponentProps={{
                type: 'button',
                text: `Delete ${Group.getHumanReadableEntityName(
                  false,
                  true,
                )}`,
              }}
              group={group}
            />
          ) : (
            <Card header="Actions" size="mini">
              <Flex spacing="gap" wrap vAlignContent="center">
                <GroupActionDelete
                  actionComponentProps={{
                    type: 'button',
                    text: `Delete ${Group.getHumanReadableEntityName(
                      false,
                      true,
                    )}`,
                  }}
                  group={group}
                />
                <div style={{ width: '500px' }}>
                  Deleting this workspace will restrict access for all
                  members and they will no longer be able to share
                  content. You can restore this workspace at any time.
                </div>
              </Flex>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export const GroupSettings: React.FC<IGroupSettingsProps> = ({
  group,
}) => {
  const isGroupOwner = useHasAccessGroup('owner', group.id);

  let rows = [
    {
      header: 'Name',
      content: () => <GroupFormShowEditName group={group} />,
    },
    {
      header: 'Description',
      content: () => <GroupFormShowEditDescription group={group} />,
    },
    {
      header: 'Cover Image',
      content: () => (
        <div style={{ width: 'min-content' }}>
          <ProfilePicture
            centered={false}
            bordered
            entity={group}
            size={Size.small}
            circular={false}
            rounded
            uploadable
          />
        </div>
      ),
    },
  ];
  if (group.organization && isGroupOwner) {
    rows = [
      ...rows,
      {
        header: 'Invite Only',
        content: () => (
          <div>
            <GroupActionSetIsInviteOnly group={group} />
            <p>
              Disable to allow anyone from {group.organization.name}{' '}
              to join the {Group.getHumanReadableEntityName()}{' '}
            </p>
          </div>
        ),
      },
      {
        header: 'Auto invite',
        content: () => (
          <div>
            <GroupActionSetIsAutoMember group={group} />
            <p>
              Enable for new members of {group.organization.name} to
              automatically join the{' '}
              {Group.getHumanReadableEntityName()}{' '}
            </p>
          </div>
        ),
      },
    ];
  }

  if (isGroupOwner) {
    rows = [
      ...rows,
      {
        header: 'Default Role',
        content: () => (
          <div>
            <GroupActionSetDefaultUserGroupType group={group} />
          </div>
        ),
      },
    ];
  }

  return (
    <Mobile>
      {({ match }) => (
        <GroupContent
          isMobile={match}
          isGroupOwner={isGroupOwner as boolean}
          rows={rows}
          group={group}
        />
      )}
    </Mobile>
  );
};

export default GroupSettings;

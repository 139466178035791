import { PubChemMetadata } from 'labstep-web/containers/PubChem/types';
import { Chemical } from 'labstep-web/models/chemical.model';
import { Molecule } from 'labstep-web/models/molecule.model';
import { appendUniqueValuesToArray } from 'labstep-web/state/reducers/helpers';
import { cloneDeep } from 'lodash';

export const getUpdatedPubChemBody = (
  entity: Molecule | Chemical,
  pubChemMetadata: PubChemMetadata,
  type: 'pictograms' | 'ghs_hazard_statements' | 'precautionary',
  code: string,
  action: 'remove' | 'add',
  subtype?:
    | 'General'
    | 'Prevention'
    | 'Response'
    | 'Storage'
    | 'Disposal',
): Record<string, unknown> => {
  let body;

  const getPubChemWithUpdatedSafety = (): PubChemMetadata => {
    const clone = cloneDeep(pubChemMetadata);
    if (type === 'precautionary') {
      clone.Safety[type][subtype] =
        action === 'add'
          ? appendUniqueValuesToArray(
              [code],
              clone.Safety[type][subtype],
            )
          : clone.Safety[type][subtype].filter((c) => c !== code);

      return clone;
    }
    clone.Safety[type] =
      action === 'add'
        ? appendUniqueValuesToArray([code], clone.Safety[type])
        : clone.Safety[type].filter((c) => c !== code);
    return clone;
  };

  if (entity instanceof Molecule) {
    const updatedPubChemMetadata = getPubChemWithUpdatedSafety();
    const reactants = entity.pubchem.reactants.map((r) =>
      r === pubChemMetadata ? updatedPubChemMetadata : r,
    );
    const products = entity.pubchem.products.map((r) =>
      r === pubChemMetadata ? updatedPubChemMetadata : r,
    );
    body = {
      pubchem: {
        reactants,
        products,
      },
    };
  } else {
    body = {
      properties: getPubChemWithUpdatedSafety(),
    };
  }
  return body;
};

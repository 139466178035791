import React from 'react';
import cx from 'classnames';
import Loadable from 'labstep-web/core/Loadable';
import { ICoreCodeMirrorProps, ICoreCodeMirrorState } from './types';
import styles from './styles.module.scss';

const CodeMirror = React.lazy(() => import('./imports'));

export class CoreCodeMirror extends React.Component<
  ICoreCodeMirrorProps,
  ICoreCodeMirrorState
> {
  public static defaultProps = {
    readOnly: false,
    fullHeight: false,
  };

  public constructor(props: ICoreCodeMirrorProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

    const value = props.value || '';
    this.state = { value };
  }

  public handleChange(value: string): void {
    const { onChange } = this.props;
    this.setState({ value });
    if (onChange) {
      onChange(value);
    }
  }

  public render(): React.ReactNode {
    const { readOnly, mode, fullHeight } = this.props;

    const value = readOnly ? this.props.value : this.state.value;

    return (
      <div
        className={cx({
          [styles.fullHeight]: fullHeight,
        })}
      >
        <Loadable>
          <CodeMirror
            lang={mode}
            value={value}
            onChange={this.handleChange}
            theme="dark"
            readOnly={readOnly}
          />
        </Loadable>
      </div>
    );
  }
}

export default CoreCodeMirror;

/**
 * Labstep
 *
 * @module components/OrganizationPlan/Action/Upgrade
 * @desc Action to open stripe billing portal
 */

import { ICONS } from 'labstep-web/constants/icons';
import { defaultCurrency } from 'labstep-web/constants/organization-plan-price';
import { PlanTier } from 'labstep-web/constants/plans';
import { StripeContainer } from 'labstep-web/containers/Stripe';
import ActionComponent from 'labstep-web/core/Action/Component';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { OrganizationPlanPrice } from 'labstep-web/models/organization-plan-price.model';
import { CurrencyISO } from 'labstep-web/services/currency.service';
import { intercomService } from 'labstep-web/services/intercom.service';
import { navigation } from 'labstep-web/services/navigation';
import { windowService } from 'labstep-web/services/window.service';
import React, { useState } from 'react';
import OrganizationPlanHubspotForm from '../../HubspotForm';
import OrganizationPlanManagerPrice from '../../Manager/Price';
import { OrganizationPlanManagerSeats } from '../../Manager/Seats';
import styles from './styles.module.scss';
import {
  IOrganizationPlanActionUpgradeContainerProps,
  IOrganizationPlanActionUpgradeProps,
} from './types';

export const ORGANIZATION_PLAN_ACTION_UPGRADE_ROUTE_ID = 'upgrade';

export const OrganizationPlanActionUpgrade: React.FC<
  IOrganizationPlanActionUpgradeProps<'mini' | 'fullscreen'>
> = ({
  organization,
  createStripeCheckoutSession,
  createStripeCheckoutSessionStatus,
  ...rest
}) => {
  const [activePlanPrice, setActivePlanPrice] =
    useState<OrganizationPlanPrice | null>(null);
  const [priceCurrency, setPriceCurrency] = useState<CurrencyISO>(
    organization.currency || defaultCurrency,
  );
  const [isHubspot, setIsHubspot] = useState(false);

  const shinyUpgrade =
    organization.tier !== PlanTier.Pro &&
    organization.tier !== PlanTier.Ultimate;
  const [maxSeats, setMaxSeats] = useState(organization.seats);

  return (
    <>
      {isHubspot ? (
        <OrganizationPlanHubspotForm
          onDone={() => setIsHubspot(false)}
        />
      ) : (
        <ModalWizard
          mode="mini"
          className={styles.modal}
          routeId={ORGANIZATION_PLAN_ACTION_UPGRADE_ROUTE_ID}
          title="Upgrade Now"
          trigger={({ toggleModal }) => (
            <ActionComponent
              type="button"
              text="Upgrade"
              showIcon
              icon={ICONS.upgrade.primary}
              onClick={() => {
                intercomService.trackEvent('click-upgrade-plan', {});
                toggleModal();
              }}
              elementProps={{
                className: shinyUpgrade ? styles.shiny : '',
                borderless: true,
                basic: !shinyUpgrade,
              }}
            />
          )}
          startIndex={organization.is_stripe_subscription_set ? 2 : 0}
          steps={['Select a plan', 'Select Seats']}
          {...rest}
        >
          <ModalWizard.Step
            index={0}
            hideHeader
            onContinue={({ goForward, reset }) => {
              if (activePlanPrice?.tier === 'pro') {
                intercomService.trackEvent('select-pro-upgrade', {});
                setIsHubspot(true);
                reset();
              } else {
                intercomService.trackEvent(
                  'select-basic-upgrade',
                  {},
                );
                goForward();
              }
            }}
          >
            <OrganizationPlanManagerPrice
              organization={organization}
              priceCurrency={priceCurrency}
              setPriceCurrency={setPriceCurrency}
              activePlanPrice={activePlanPrice}
              setActivePlanPrice={setActivePlanPrice}
            />
          </ModalWizard.Step>
          <ModalWizard.Step
            index={1}
            hideHeader
            continueLabel="Setup Payment"
            continueText="Continuing will take you off-site to Stripe’s payment gateway"
            disableContinueButton={
              organization.seats > 5 ||
              createStripeCheckoutSessionStatus?.isFetching
            }
            onContinue={() => {
              if (!activePlanPrice) {
                return;
              }
              intercomService.trackEvent('checkout-upgrade', {});
              createStripeCheckoutSession(
                {
                  price_id: activePlanPrice?.id,
                  currency: priceCurrency,
                  quantity: maxSeats,
                  success_url: navigation.get(
                    'organization_show_billing',
                    { identifier: organization.identifier },
                    true,
                  ),
                  cancel_url: window.location.href,
                },
                {
                  onSuccess: ({ response }) => {
                    windowService.setLocation(response.url);
                  },
                },
              );
            }}
          >
            <OrganizationPlanManagerSeats
              organization={organization}
              priceCurrency={priceCurrency}
              activePlanPrice={activePlanPrice!}
              maxSeats={maxSeats}
              setMaxSeats={setMaxSeats}
            />
          </ModalWizard.Step>
        </ModalWizard>
      )}
    </>
  );
};

const OrganizationPlanActionUpgradeContainer: React.FC<
  IOrganizationPlanActionUpgradeContainerProps<'mini' | 'fullscreen'>
> = (props) => (
  <StripeContainer>
    {({
      createStripeCheckoutSession,
      createStripeCheckoutSessionStatus,
    }) => (
      <OrganizationPlanActionUpgrade
        createStripeCheckoutSession={createStripeCheckoutSession}
        createStripeCheckoutSessionStatus={
          createStripeCheckoutSessionStatus
        }
        {...props}
      />
    )}
  </StripeContainer>
);

export default OrganizationPlanActionUpgradeContainer;

import { IFilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { PostFilterAvailableItems } from 'labstep-web/services/postFilter/filters/availableItems';
import React from 'react';

export const FilterAvailableResourceItemCountActivePost: React.FC<
  IFilterPostActiveLabelProps
> = ({ onRemoveClick, node }) => {
  const { available_resource_item_count: attribute } =
    PostFilterAvailableItems.getAttributeValues(node);
  if (!attribute) {
    return null;
  }

  return (
    <>
      {attribute.comparison === 'gte' && (
        <RemoveLabel
          name={`≥ ${attribute.value} available items`}
          onRemoveClick={onRemoveClick}
        />
      )}
      {attribute.comparison === 'lte' && (
        <RemoveLabel
          name={`≤ ${attribute.value} available items`}
          onRemoveClick={onRemoveClick}
        />
      )}
    </>
  );
};

export default FilterAvailableResourceItemCountActivePost;

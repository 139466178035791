import { Column, ColumnState } from 'ag-grid-community';

/**
 * Find the column state of a column
 * @param column Column
 * @param columnState Column state array
 * @returns Column state for column
 */
export const matchColumnStateToColumn = (
  column: Column,
  columnState: ColumnState[],
): ColumnState | undefined =>
  columnState.find(
    (columnStateItem) => columnStateItem.colId === column.getColId(),
  );

/**
 * Filter for pinned columns that cannot change position
 * @param columns Column array
 * @returns Column array
 */
export const filterPinnedLeftColumnDefs = (
  columns: Column[],
  columnState: ColumnState[],
): Column[] =>
  columns.filter((column) => {
    const columnMatch = matchColumnStateToColumn(column, columnState);
    if (!columnMatch) {
      return false;
    }
    const { pinned } = columnMatch;
    return pinned === 'left' || pinned === true;
  });

/**
 * Filter for pinned columns that cannot change position
 * @param columns Column array
 * @returns Column array
 */
export const filterPinnedRightColumnDefs = (
  columns: Column[],
  columnState: ColumnState[],
): Column[] =>
  columns.filter((column) => {
    const columnMatch = matchColumnStateToColumn(column, columnState);
    if (!columnMatch) {
      return false;
    }
    const { pinned } = columnMatch;
    return pinned === 'right';
  });

/**
 * Filter out pinned columns that cannot change position
 * @param columns Column array
 * @returns Column array
 */
export const filterNoPinnedColumnDefs = (
  columns: Column[],
  columnState: ColumnState[],
): Column[] =>
  columns.filter(
    (column) =>
      !matchColumnStateToColumn(column, columnState)?.pinned,
  );

/**
 * Filter for shown columns that can change position
 * @param columns Column array
 * @param columnState Column state array
 * @returns Column array
 */
export const filterShownAndNotPinnedPositionColumnDefs = (
  columns: Column[],
  columnState: ColumnState[],
): Column[] =>
  filterShownColumnDefs(
    filterNoPinnedColumnDefs(columns, columnState),
    columnState,
  );

/**
 * Filter out hidden columns
 * @param columns Column array
 * @param columnState Column state array
 * @returns Column array
 */
export const filterShownColumnDefs = (
  columns: Column[],
  columnState: ColumnState[],
): Column[] =>
  columns.filter(
    (column) =>
      matchColumnStateToColumn(column, columnState)?.hide === false,
  );

/**
 * Filter out shown columns
 * @param columns Column array
 * @param columnState Column state array
 * @returns Column array
 */
export const filterHiddenColumnDefs = (
  columns: Column[],
  columnState: ColumnState[],
): Column[] =>
  columns.filter(
    (column) =>
      matchColumnStateToColumn(column, columnState)?.hide === true,
  );

/**
 * Get an array of column ids
 * @param columns Column array
 * @returns Column id array
 */
export const getColIdArr = (
  columns: Column[],
): { colId: string }[] => {
  return columns.map((column) => ({ colId: column.getColId() }));
};

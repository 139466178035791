import React from 'react';
import PaginationCount from 'labstep-web/core/Pagination/Count';
import PaginationCountPerPage from 'labstep-web/core/Pagination/CountPerPage';
import { useParamsContext } from 'labstep-web/hoc/Params/context';
import SemanticPagination from 'semantic-ui-react/dist/commonjs/addons/Pagination/Pagination';
import { IPaginationProps } from './types';
import styles from './styles.module.scss';

const Pagination: React.FC<IPaginationProps> = ({
  count,
  availableCounts,
  countPerPage,
  setCountPerPage,
}) => {
  const { searchParams, setParams } = useParamsContext();
  const lastPage = Math.ceil(count / countPerPage);

  const activePage = Number(searchParams.page || 1);
  const to =
    activePage === lastPage ? count : countPerPage * activePage;
  const from = 1 + countPerPage * (activePage - 1);

  const isLeftDisabled = !(activePage > 1);
  const isRightDisabled = !(activePage < lastPage);

  if (count === 0) {
    return null;
  }
  return (
    <div className={styles.pagination}>
      <PaginationCount from={from} to={to} total={count} />
      <PaginationCountPerPage
        countPerPage={countPerPage}
        setCountPerPage={setCountPerPage}
        availableCounts={availableCounts}
      />
      {lastPage > 1 && (
        <SemanticPagination
          size="mini"
          boundaryRange={0}
          ellipsisItem={null}
          activePage={activePage}
          firstItem={null}
          lastItem={null}
          prevItem={{
            'aria-label': 'Previous item',
            content: '⟨',
            disabled: isLeftDisabled,
          }}
          nextItem={{
            'aria-label': 'Next item',
            content: '⟩',
            disabled: isRightDisabled,
          }}
          siblingRange={1}
          totalPages={lastPage}
          onPageChange={(e, { activePage: page }) =>
            setParams({ page: page === 1 ? undefined : page })
          }
        />
      )}
    </div>
  );
};

export default Pagination;

import { entityUserRole } from 'labstep-web/fixtures/entity-user-role';
import { OptionsGroup } from '../components/EntityUserRole/SelectPanel/types';

export const systemOptions: OptionsGroup[] = [
  {
    label: 'System',
    options: [
      {
        value: 'creator',
        label: 'Creator',
        icon: 'user circle',
        description: 'The user who created the Experiment',
        entity: entityUserRole,
      },
      {
        value: 'contributor',
        label: 'Contributor',
        icon: 'group',
        description: 'Anyone who has made changes to the Experiment',
        entity: entityUserRole,
      },
    ],
  },
];

import { useHasAccess } from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import PopupContainer from 'labstep-web/core/Popup';
import React from 'react';
import { CirclePicker } from 'react-color';
import { IEntityStateActionUpdateColorProps } from './types';

const EntityStateActionUpdateColor: React.FC<
  IEntityStateActionUpdateColorProps
> = ({ entityState }) => {
  const canEdit = useHasAccess(
    entityState.entityName,
    entityState.id,
    Action.edit,
  );
  return (
    <EntityUpdateContainer
      entityName={entityState.entityName}
      id={entityState.id}
    >
      {({ update }) => (
        <PopupContainer
          disabled={!canEdit}
          on="click"
          position="bottom left"
          inverted={false}
          trigger={
            <div
              style={{
                width: '20px',
                height: '20px',
                backgroundColor: entityState.color,
              }}
            />
          }
          content={
            <CirclePicker
              onChangeComplete={(color) => {
                update({ color: color.hex });
              }}
            />
          }
        />
      )}
    </EntityUpdateContainer>
  );
};

export default EntityStateActionUpdateColor;

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  share = 'share',
  duplicate = 'duplicate',
  print = 'print',
  lock = 'lock',
  delete = 'delete',
  tag = 'tag',
  export = 'export',
  assign = 'assign',
  revoke = 'revoke',
  transfer_ownership = 'transfer_ownership',
  add_to_folder = 'add_to_folder',
  remove_from_folder = 'remove_from_folder',
}

export interface IExperimentWorkflowActionMenuProps
  extends Pick<IActionMenuProps, 'type'> {
  experimentWorkflow: ExperimentWorkflow;
  actions: Action[];
}

import { Filter } from 'labstep-web/components/Filter/Menu/types';
import { PermissionRoleSections } from 'labstep-web/constants/roles';
import { MetadataType } from 'labstep-web/models/metadata/types';
import { PremiumFeature } from 'labstep-web/models/organization.model';

export type Source =
  | 'filters'
  | 'metadata'
  | 'bulk_metadata'
  | 'metadata_detail_table'
  | 'categories'
  | 'referencing_menu_categories'
  | 'conditions_table'
  | 'global_search'
  | 'slash_commands'
  | 'sub_links'
  | 'order_request_action';

export type PremiumKey =
  | Filter
  | MetadataType
  | PermissionRoleSectionKey
  | string;

type FeatureConfig = {
  premiumKeys: PremiumKey[];
  show: Source[];
  hide: Source[];
};

export type PremiumFeatures = Partial<
  Record<PremiumFeature, FeatureConfig>
>;

export type OptionWithConfig<T> = T & {
  premiumFeature?: PremiumFeature;
  show: boolean;
  premiumFeatureEnabled: boolean;
};

type PermissionRoleSectionKey = keyof typeof PermissionRoleSections;

export const PermissionRoleSectionKeys: Record<
  PermissionRoleSectionKey,
  string
> = Object.keys(PermissionRoleSections).reduce(
  (acc, key) => {
    acc[key as PermissionRoleSectionKey] = key;
    return acc;
  },
  {} as Record<PermissionRoleSectionKey, string>,
);

/**
 * Labstep
 *
 * @module prosemirror/nodes/paragraph
 * @desc Paragraph node
 */

/**
 * @jest-environment jsdom
 */
import { Node as ProseMirrorNode } from 'prosemirror-model';
import { LabstepNode } from 'labstep-web/prosemirror/nodes/types';
import { setBlockType } from 'prosemirror-commands';

interface Paragraph {
  attrs: {
    align: 'left' | 'right' | 'center' | 'justify';
  };
}

const NodeParagraph: LabstepNode = {
  key: 'paragraph',
  spec: {
    attrs: {
      align: { default: null },
    },
    content: 'inline*',
    group: 'block',
    parseDOM: [
      {
        tag: 'p',
        getAttrs(dom): Paragraph['attrs'] | null {
          if (dom instanceof HTMLElement) {
            const { textAlign } = dom.style;
            if (
              ['left', 'center', 'right', 'justify'].indexOf(
                textAlign,
              ) > -1
            ) {
              const align: Paragraph['attrs']['align'] =
                textAlign as Paragraph['attrs']['align'];
              return {
                align,
              };
            }
            return null;
          }
          return null;
        },
      },
    ],
    toDOM(node: ProseMirrorNode) {
      const { align } = node.attrs;
      const attrs: any = {};

      let style = '';
      if (align && align !== 'left') {
        style += `text-align: ${align};`;
      }

      if (style) {
        attrs.style = style;
      }

      return ['p', attrs, 0];
    },
  },
  keymap: {
    'Shift-Ctrl-0': (state, dispatch) =>
      setBlockType(state.schema.nodes[NodeParagraph.key])(
        state,
        dispatch,
      ),
  },
};

export default NodeParagraph;

import { getFieldAutomation } from 'labstep-web/components/EntityUserRoleRequirement/Form/fields';
import { FieldTypeReactSelect } from 'labstep-web/core/Form/Reusable/types';
import FormShowEditSelect from 'labstep-web/core/Form/ShowEdit/Select';
import { EntityUserRoleRequirement } from 'labstep-web/models/entity-user-role-requirement.model';
import { isMatch } from 'lodash';
import React from 'react';
import {
  AutomationOption,
  IEntityUserRoleRequirementFormShowEditAutomationProps,
} from './types';

const getSelectedAutomation = (
  field: FieldTypeReactSelect,
  entityUserRoleRequirement: EntityUserRoleRequirement,
) => {
  const { options } = field.elementProps;

  const matchingOption: AutomationOption | undefined = (
    options as AutomationOption[]
  ).find((option) => {
    if (option.value === null) {
      return entityUserRoleRequirement.automation === null;
    }
    return isMatch(
      entityUserRoleRequirement.automation,
      (option as AutomationOption).value,
    );
  });

  if (!matchingOption) {
    return 'Custom Rule';
  }

  return matchingOption.label;
};

const EntityUserRoleRequirementFormShowEditAutomation: React.FC<
  IEntityUserRoleRequirementFormShowEditAutomationProps
> = ({
  entityState,
  entityUserRoleRequirement,
  entityStateWorkflowId,
}) => {
  const field = getFieldAutomation(
    entityStateWorkflowId,
    entityState.id,
    entityUserRoleRequirement.entity_user_role.id,
  );
  return (
    <FormShowEditSelect
      entity={entityUserRoleRequirement}
      field={field}
      isClearable={false}
      hasValue
    >
      {getSelectedAutomation(
        field as FieldTypeReactSelect,
        entityUserRoleRequirement,
      )}
    </FormShowEditSelect>
  );
};

export default EntityUserRoleRequirementFormShowEditAutomation;

import LayoutContainerActionButtons from 'labstep-web/components/Layout/Container/ActionButtons';
import Button from 'labstep-web/core/Button';
import React, { useState } from 'react';
import { IReusableFormProps } from '../Reusable/types';

export const useResetForm = (
  disableAddAnother?: boolean,
): {
  key: number;
  submitButton: IReusableFormProps['submitButton'];
  onSuccess: (fn: VoidFunction) => void;
} => {
  const [reset, setReset] = useState<boolean>(false);
  const [key, setKey] = useState<number>(1);

  const submitButton: IReusableFormProps['submitButton'] = ({
    status,
  }) => (
    <LayoutContainerActionButtons>
      <Button
        status={status}
        type={reset ? 'button' : 'submit'}
        primary
        onClick={(): void => setReset(false)}
      >
        Done
      </Button>
      {!disableAddAnother && (
        <Button
          status={status}
          type={reset ? 'submit' : 'button'}
          primary
          basic
          onClick={(): void => setReset(true)}
        >
          Add another
        </Button>
      )}
    </LayoutContainerActionButtons>
  );

  const onSuccess = (fn: VoidFunction): void => {
    if (reset) {
      setKey(key + 1);
    } else {
      fn();
    }
  };

  return {
    key,
    submitButton,
    onSuccess,
  };
};

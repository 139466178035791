import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import React from 'react';

export const actionComponentProps = {
  dataTestId: 'entity-view-save',
  type: 'button',
  showIcon: true,
  icon: 'save',
  text: 'Save View',
  elementProps: {
    basic: true,
  },
  modalConfirmationProps: {
    header: 'Warning',
    positive: false,
    message: (
      <>
        <p>
          If you save your changes to the view, the order, width and
          visibility of columns will become the default for everyone
          in the workspace.
        </p>
        <p>Are you sure you want to proceed?</p>
      </>
    ),
  },
} as IActionComponentProps;

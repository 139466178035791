import { ResourceCustomIdentifierContainer } from 'labstep-web/containers/Resource/CustomIdentifier';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import React from 'react';
import { IResourceActionAddCustomIdentifierProps } from './types';

export const ResourceActionAddCustomIdentifier: React.FC<
  IResourceActionAddCustomIdentifierProps
> = ({ resource }) => (
  <ResourceCustomIdentifierContainer>
    {({ createResourceCustomIdentifier }) => (
      <ActionComponent
        type="text"
        text="Assign identifier"
        icon="crosshairs"
        onClick={() => {
          createResourceCustomIdentifier(resource.id);
        }}
      />
    )}
  </ResourceCustomIdentifierContainer>
);

import { IParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

export const getHasActiveFilter = (
  searchParams: IParamsHOCChildrenProps['searchParams'],
  showSearchQuery?: boolean,
): boolean => {
  const ignore = [
    'sort',
    'page',
    'folder_id',
    'folder_group_not_id',
    'template_id',
    'has_template',
  ];
  if (!showSearchQuery) {
    ignore.push('search_query');
  }
  // we ignore filter if there's a filter with no user-defined predicates
  // this means that there are system-defined predicates
  if (searchParams.filter?.[0].predicates.length === 0) {
    ignore.push('filter');
  }
  return !isEmpty(omit(searchParams, ignore));
};

import {
  GridApi,
  GridReadyEvent,
  ProcessDataFromClipboardParams,
} from 'ag-grid-community';
import ActionComponent from 'labstep-web/core/Action/Component';
import DataGrid from 'labstep-web/core/DataGrid';
import Flex from 'labstep-web/core/Flex';
import Message from 'labstep-web/core/Message';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { User, UsersDataGridProps } from './types';
import {
  columnDefs,
  getDefaultRowData,
  isInvalidGrid,
  onCellEditingStarted,
  onCellEditingStopped,
  processPastedData,
  getEditableRowCount,
  ADDING_ROW_CTA,
} from './utils';

export const CreateUsers: React.FC<UsersDataGridProps> = ({
  toggleModal,
  create,
  status,
  availableSeats,
}) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);

  const { rowData, rowCount } = getDefaultRowData(availableSeats);
  const [editableRowCount, setEditableRowCount] = useState(rowCount);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const processDataFromClipboard = (
    params: ProcessDataFromClipboardParams,
  ): string[][] | null => {
    processPastedData(params, availableSeats);
    return params.data;
  };

  const handleAddUsers = () => {
    const newUsers: {
      first_name: string;
      last_name: string;
      email: string;
    }[] = [];

    gridApi!.forEachNode((rowNode) => {
      const data = rowNode.data as User;
      if (
        data.firstName !== ADDING_ROW_CTA &&
        data.firstName !== '' &&
        data.lastName !== '' &&
        data.email !== ''
      ) {
        newUsers.push({
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
        });
      }
    });

    if (newUsers.length === 0) {
      toggleModal();
      return;
    }

    create(newUsers, {
      onSuccess: () => {
        toggleModal();
      },
    });
  };

  return (
    <>
      <DataGrid
        className="create-users-grid"
        rowClass={styles.rowStyle}
        rowSelection="multiple"
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        stopEditingWhenCellsLoseFocus
        processDataFromClipboard={processDataFromClipboard}
        onPasteEnd={(e) => {
          setEditableRowCount(getEditableRowCount(e.api));
        }}
        onCellEditingStarted={(e) => {
          onCellEditingStarted(e, availableSeats);
          setEditableRowCount(getEditableRowCount(e.api));
        }}
        onCellEditingStopped={onCellEditingStopped}
        onCellValueChanged={(e) => {
          setIsInvalid(isInvalidGrid(e));
          e.api.refreshCells({
            rowNodes: [e.node],
            force: true,
            suppressFlash: true,
          });
        }}
      />
      {availableSeats && editableRowCount >= availableSeats ? (
        <Message
          warning
          className={styles.warning}
          content={
            <span>
              You can only add {availableSeats} more users before
              reaching the user limit for your plan. Please contact{' '}
              <a href="mailto:sales@labstep.com">sales@labstep.com</a>{' '}
              or your account manager to add more.
            </span>
          }
        />
      ) : (
        <Message
          className={styles.note}
          list={[
            'Users who already have an account will receive an invitation and only be added once they accept.',
            'You must include a first and last name along with a valid email address to add or invite users.',
            'You cannot add or invite more users than the number of seats on your plan will allow.',
          ]}
        />
      )}
      <Flex className={styles.actionButton}>
        <ActionComponent
          onClick={() => {
            handleAddUsers();
          }}
          status={status}
          type="button"
          text="Add"
          disabled={isInvalid}
        />
      </Flex>
    </>
  );
};

export default CreateUsers;

/**
 * Labstep
 *
 * @module components/Filter/User
 * @desc User SearchSelect filter
 */

import React from 'react';
import Modal from 'labstep-web/core/Modal/Default';
import ActionComponent from 'labstep-web/core/Action/Component';
import UserSearchSelect from 'labstep-web/components/User/SearchSelect';
import { IFilterUserProps } from './types';
import { onUserChange } from './utils';

export const FilterUser: React.FC<IFilterUserProps> = ({
  setParams,
  icon,
  header,
  type,
  addPostFilter,
}: IFilterUserProps) => (
  <Modal
    header={header}
    content={({ toggleModal }): React.ReactElement => (
      <UserSearchSelect
        onChange={(selectedOption): void => {
          onUserChange(
            selectedOption,
            type,
            setParams,
            addPostFilter,
          );
          toggleModal();
        }}
        placeholder="Select user(s) from the list below"
        minimumCharacters={3}
      />
    )}
    viewComponent={({ toggleModal }): React.ReactElement => (
      <ActionComponent
        type="option"
        icon={icon}
        text={header}
        onClick={toggleModal}
      />
    )}
  />
);

export default FilterUser;

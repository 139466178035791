import { CalendarOptions, PluginDef } from '@fullcalendar/common';

// eslint-disable-next-line no-shadow
export enum IPluginName {
  timeline = 'timeline',
  timeGrid = 'timeGrid',
}

export type IAvailablePlugins = {
  [key in IPluginName]: PluginDef[];
};

export interface IFullCalendarProps extends CalendarOptions {
  ref?: any;
  isGrid?: boolean;
  pluginName: IPluginName;
  className?: string;
}

import React, { ComponentProps } from 'react';
import { components } from 'react-select';
import { humanReadableDate } from 'labstep-web/services/date.service';
import { DeviceData } from 'labstep-web/models/device-data.model';

export const deviceDataGetOptionLabel = (deviceData: DeviceData) =>
  // Need to use getDeviceDataPrintValue because deviceData is not passed
  // as a DeviceData class
  `${deviceData.name} - ${humanReadableDate(deviceData.created_at)}`;

export const DeviceDataOptionComponent = (
  props: ComponentProps<typeof components.Option> & {
    data: DeviceData;
  },
) => {
  const option = props.data;

  return (
    <components.Option {...props}>
      {`${option.name} - ${option.printValue} - ${humanReadableDate(
        option.created_at,
      )}`}
    </components.Option>
  );
};

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';
import { SignatureRequirement } from 'labstep-web/models/signature-requirement.model';

export const getSignatureRequirementForUser = (
  experimentWorkflow: ExperimentWorkflow,
): SignatureRequirement => {
  return experimentWorkflow.entity_state
    .entity_user_role_requirements[0]
    ?.signature_requirement as SignatureRequirement;
};

/**
 * Labstep
 *
 * @module models/organization-plan-price
 * @desc Typescript export class for OrganizationPlanPrice
 */

import { CurrencyISO } from 'labstep-web/services/currency.service';
import { Organization } from './organization.model';

export class OrganizationPlanPrice {
  static readonly entityName = 'organization_plan_price';

  get entityName(): string {
    return OrganizationPlanPrice.entityName;
  }

  constructor(data: Partial<OrganizationPlanPrice>) {
    Object.assign(this, data);
  }

  id!: string;

  _tier!: Organization['tier'];

  currency!: CurrencyISO;

  unit_amount!: number;

  interval!: 'day' | 'week' | 'month' | 'year';

  get tier(): Organization['tier'] {
    return this._tier || 'basic';
  }

  set tier(tier: Organization['tier']) {
    this._tier = tier;
  }
}

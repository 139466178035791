/**
 * Labstep
 *
 *
 * @module core/Form/Reusable/Fields
 * @desc Fields for reusable form
 */

import Form from 'labstep-web/core/Form';
import { TextArea } from 'labstep-web/core/Form/ReactForm/TextArea';
import { isEmpty } from 'lodash';
import debounce from 'lodash/debounce';
import React from 'react';
import Message from '../../ReactForm/Errors/Message';
import FormReusableLabel from '../Label';
import { FieldType as FieldTypeEnum } from '../types';
import { IFormReusableFieldsProps } from './types';
import { getErrorMessage, getFieldType } from './utils';

export const FormReusableFields: React.FC<
  IFormReusableFieldsProps
> = ({
  fields,
  values,
  reset,
  submitForm,
  disabled,
  autoFocus,
  blurToggle,
  errors,
  control,
}) => {
  const onBlur = (): void => {
    if (!blurToggle) {
      return;
    }
    if (!isEmpty(errors)) {
      blurToggle();
    } else {
      submitForm();
    }
  };

  return (
    <>
      {fields.map((field, index) => {
        // Renders an action instead of a field. Use for dynamic forms.
        if (field.fieldType === 'action') {
          return field.component({ values, reset });
        }

        const { fieldType, fieldLabel, fieldExplainer } = field;

        const FieldType = getFieldType(fieldType);

        let key = field.name;
        if (field.fieldType === FieldTypeEnum.DateTimePicker) {
          key = `${field.name}-${field.elementProps?.enableTime}`;
        }

        const onKeyPress = (e: KeyboardEvent): boolean => {
          if (e.key === 'Escape' && blurToggle) {
            blurToggle();
          }
          debounce(() => {
            if (
              !disabled &&
              FieldType !== TextArea &&
              e.key === 'Enter'
            ) {
              submitForm();
            }
          }, 100);
          return false;
        };
        const errorMessage = getErrorMessage(errors, field);

        return (
          <Form.Field key={key}>
            {fieldLabel && (
              <FormReusableLabel
                fieldExplainer={fieldExplainer}
                fieldLabel={fieldLabel}
              />
            )}
            <FieldType
              name={field.name}
              autoFocus={
                field.autoFocus || (!!autoFocus && index === 0)
              }
              disabled={!!(disabled || field.disabled)}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
              elementProps={field.elementProps}
              control={control}
              placeholder={field.placeholder}
            />
            {errorMessage && <Message message={errorMessage} />}
          </Form.Field>
        );
      })}
    </>
  );
};

export default FormReusableFields;

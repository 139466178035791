import { IParamsHOCChildrenProps } from 'labstep-web/hoc/Params/types';
import { PostFilterResourceLocation } from 'labstep-web/services/postFilter/filters/resourceLocation';
import { ITreeContainerProps, TreeEntity } from './types';

export const sortByName = (entities: TreeEntity[]): TreeEntity[] =>
  entities.sort((a, b) =>
    a.name ? a.name.localeCompare(b.name) : 1,
  );

/**
 * Get a function to set the active tree entity
 * Based on post filter
 * @param filter Post filter
 * @param addPostFilter Add post filter function
 * @param setParams Set params function
 * @returns Function to set active tree entity
 */
export const getSetActiveTreeEntity =
  (
    setParams: IParamsHOCChildrenProps['setParams'],
    addPostFilter: IParamsHOCChildrenProps['addPostFilter'],
    filter?: typeof PostFilterResourceLocation,
  ) =>
  (
    entityName: ITreeContainerProps['entityName'],
    entity?: TreeEntity,
    unsorted?: ITreeContainerProps['unsorted'],
  ): void => {
    if (filter) {
      addPostFilter(filter.getNode([entity?.guid]), true);
    } else {
      setParams({
        [`${entityName}_id`]: entity?.id,
        ...(unsorted && {
          [unsorted.param]: undefined,
        }),
      });
    }
  };

/**
 * Check if a tree entity is active
 * @param searchParams Search params
 * @param entity Entity
 * @param filter Post filter
 * @returns True if active
 */
export const isActiveTreeEntity = (
  searchParams: Record<string, unknown>,
  entity: TreeEntity,
  filter?: typeof PostFilterResourceLocation,
): boolean =>
  filter
    ? !!filter.getNodeInParams(searchParams, [entity.guid])
    : Number(searchParams[`${entity.entityName}_id`]) === entity.id;

import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';

// eslint-disable-next-line no-shadow
export enum Action {
  view = 'view',
  delete = 'delete',
}

export interface IProtocolValueActionMenuProps
  extends Pick<
    IActionMenuProps,
    Exclude<keyof IActionMenuProps, 'children'>
  > {
  protocolValue: ProtocolValue;
  actions: Action[];
}

import { Resource } from 'labstep-web/models/resource.model';
import { getMetadataColDefs } from 'labstep-web/components/Entity/DataGrid/colDefs/metadatas';

/**
 * For all metadatas on resource item template, return a column
 * @param resourceTemplate Resource template
 * @returns Column definitions
 */
export const getResourceMetadataColDefs = (
  resourceTemplate: Resource,
) =>
  getMetadataColDefs(
    Resource.entityName,
    resourceTemplate,
    undefined,
    { entityName: Resource.entityName },
  );

/**
 * Labstep
 *
 * @module components/EntityImport/Action/Create/Steps/Preview
 * @desc EntityImport Create Step 4: Preview
 */

import React, { useState } from 'react';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import EntityImportDataGrid from 'labstep-web/components/EntityImport/DataGrid';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import {
  DataGridContextProvider,
  useAgGridEntityImportServiceFromContext,
} from 'labstep-web/core/DataGrid/context';
import { useActiveGroup } from 'labstep-web/hooks/activeGroup';
import ModalConfirmation from 'labstep-web/core/Modal/Confirmation';
import { useModalWizardContext } from 'labstep-web/core/Modal/Wizard/context';
import { AGGridEntityImportValidationService } from 'labstep-web/services/agGrid/ag-grid-entity-import-validation.service';
import styles from './styles.module.scss';
import { IEntityImportActionCreateStepsPreviewProps } from './types';
import { WarningModal } from './WarningModal/WarningModal';

const getErrorMessage = (count: number, type: 'row' | 'column') =>
  `${count} invalid ${type}${count > 1 ? 's' : ''}`;

export const EntityImportActionCreateStepsPreview: React.FC<
  IEntityImportActionCreateStepsPreviewProps
> = ({
  entityName,
  index,
  template,
  columnDefs,
  rowData,
  onContinue,
  create,
}) => {
  const [showOnlyInvalidRows, setShowOnlyInvalidRows] =
    useState(false);

  const [showModalConfirmation, setShowModalConfirmation] =
    useState(false);

  const [isValid, setIsValid] = useState(true);

  const { goBack, setErrorMessages: setError } =
    useModalWizardContext();

  const agGridEntityImportService =
    useAgGridEntityImportServiceFromContext();

  const { activeGroup } = useActiveGroup();

  const [reservedIdentifiersCount, setReservedIdentifiersCount] =
    useState(0);

  const [showWarningModal, setShowWarningModal] = useState(false);

  const [continueHandlers, setContinueHandlers] = useState<{
    setErrorMessages?: (messages: string[]) => void;
    goForward?: () => void;
  }>({});

  const saveData = (
    goForward: () => void,
    setErrorMessages: (messages: string[]) => void,
  ) => {
    if (!agGridEntityImportService || !activeGroup) {
      return;
    }

    agGridEntityImportService.saveData(
      activeGroup.id,
      entityName,
      create,
      template,
      {
        onSuccess: () => {
          goForward();
          onContinue();
        },
        onFail: () => {
          setErrorMessages([
            'Something went wrong, please try again later',
          ]);
        },
      },
    );
  };

  return (
    <>
      <ModalConfirmation
        open={!!showModalConfirmation}
        header="Warning"
        message="Are you sure you want to go back? All changes will be lost."
        onConfirm={() => {
          setShowModalConfirmation(false);
          goBack();
        }}
        onCancel={() => setShowModalConfirmation(false)}
        onClose={() => setShowModalConfirmation(false)}
      />
      <ModalWizard.Step
        index={index}
        description="Here you can add and modify entries based on the field structure you have defined."
        continueLabel="Import"
        disableContinueButton={!isValid}
        onContinue={({ setErrorMessages, goForward }) => {
          if (!agGridEntityImportService || !activeGroup) {
            return;
          }
          setContinueHandlers({ setErrorMessages, goForward });
          const invalidRowCount =
            agGridEntityImportService.getInvalidRowCount();
          if (invalidRowCount > 0) {
            setErrorMessages([
              getErrorMessage(invalidRowCount, 'row'),
            ]);
          } else {
            const reservedIdentifiers =
              AGGridEntityImportValidationService.getReservedIdentifiers(
                agGridEntityImportService.getData(),
                template,
              );
            setReservedIdentifiersCount(reservedIdentifiers.length);

            if (reservedIdentifiers.length === 0) {
              setShowWarningModal(false);
              saveData(goForward, setErrorMessages);
            } else {
              setShowWarningModal(true);
            }
          }
        }}
        onBack={() => setShowModalConfirmation(true)}
        actions={
          <ActionComponent
            type="checkbox"
            elementProps={{
              label: 'Show only invalid rows',
              checked: showOnlyInvalidRows,
            }}
            onClick={(): void => {
              setShowOnlyInvalidRows(!showOnlyInvalidRows);
            }}
          />
        }
      >
        <div className={styles.container}>
          <EntityImportDataGrid
            template={template}
            targetEntityName={entityName}
            rowData={rowData}
            columnDefs={columnDefs}
            showOnlyInvalidRows={showOnlyInvalidRows}
            setErrorMessages={setError}
            setIsValid={setIsValid}
          />
        </div>
      </ModalWizard.Step>
      {showWarningModal && (
        <WarningModal
          count={reservedIdentifiersCount}
          setShowWarningModal={setShowWarningModal}
          onConfirm={() => {
            if (
              continueHandlers.goForward &&
              continueHandlers.setErrorMessages
            ) {
              saveData(
                continueHandlers.goForward,
                continueHandlers.setErrorMessages,
              );
            }
          }}
        />
      )}
    </>
  );
};

export const EntityImportActionCreateStepsPreviewContainer: React.FC<
  IEntityImportActionCreateStepsPreviewProps
> = (props) => (
  <DataGridContextProvider>
    <EntityImportActionCreateStepsPreview {...props} />
  </DataGridContextProvider>
);

export default EntityImportActionCreateStepsPreviewContainer;

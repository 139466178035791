import Can from 'labstep-web/components/Entity/Can';
import { Action } from 'labstep-web/components/Entity/Can/types';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import React from 'react';
import { ENTITY_STATE_WORKFLOW_CONFIGURE_ROUTE_ID } from '..';
import { IEntityStateWorkflowActionConfigureWorkflowModalTriggerProps } from './types';

export const EntityStateWorkflowActionConfigureWorkflowModalTrigger: React.FC<
  IEntityStateWorkflowActionConfigureWorkflowModalTriggerProps
> = ({
  entityStateWorkflow,
  buttonType = 'text',
  buttonProps,
  text = 'Configure Workflow',
  showIcon,
  viewComponent,
}) => {
  const hasSignatureWorkflowFeatureEnabled = useHasFeatureFlagEnabled(
    'signature_workflow',
  );
  if (!hasSignatureWorkflowFeatureEnabled) {
    return null;
  }
  const { toggleModal } = useModalRoute(
    ENTITY_STATE_WORKFLOW_CONFIGURE_ROUTE_ID,
  );

  const state = {
    entityStateWorkflowId: entityStateWorkflow.id,
  };

  const defaultButtonProps: any = {
    type: buttonType,
    text,
    icon: 'settings',
    showIcon,
    ...buttonProps,
  };

  if (buttonType === 'button') {
    defaultButtonProps.elementProps = {
      basic: true,
      color: undefined,
    };
  }

  return viewComponent ? (
    viewComponent({
      toggleModal: () => {
        toggleModal(state);
      },
    })
  ) : (
    <Can
      entityName={entityStateWorkflow.entityName}
      id={entityStateWorkflow.id}
      action={Action.edit}
    >
      <ActionComponent
        {...defaultButtonProps}
        onClick={() => toggleModal(state)}
      />
    </Can>
  );
};

import { IActionComponentProps } from 'labstep-web/core/Action/Component/types';
import Button from 'labstep-web/core/Button';
import Icon from 'labstep-web/core/Icon';
import { IIconProps } from 'labstep-web/core/Icon/types';
import { EntityState } from 'labstep-web/models/entity-state.model';
import React from 'react';
import ModalWizardTrigger from './ModalWizard/Trigger';
import { IExperimentWorkflowActionUpdateEntityStateProps } from './types';

const ExperimentWorkflowActionUpdateStatus: React.FC<
  IExperimentWorkflowActionUpdateEntityStateProps
> = ({ experimentWorkflow, iconColor }) => {
  const { hasNextEntityState } = experimentWorkflow;

  if (!hasNextEntityState) {
    return null;
  }

  const nextEntityState =
    experimentWorkflow.nextEntityState as EntityState;

  const actionComponentProps: IActionComponentProps = {
    type: 'children',
    children: ({ status, onClick }) => (
      <Button basic status={status} onClick={onClick}>
        <Icon
          name={nextEntityState.icon as IIconProps['name']}
          color={iconColor}
        />{' '}
        {`Mark as ${nextEntityState?.name}`}
      </Button>
    ),
  };

  return (
    <ModalWizardTrigger
      experimentWorkflow={experimentWorkflow}
      actionComponentProps={actionComponentProps}
    />
  );
};

export default ExperimentWorkflowActionUpdateStatus;

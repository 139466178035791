/**
 * Labstep
 */

import colDefEntityLink from 'labstep-web/components/Entity/DataGrid/colDefs/entityLink';
import { ICONS } from 'labstep-web/constants/icons';
import DataGridCellEditorSearchSelect from 'labstep-web/core/DataGrid/CellEditor/SearchSelect';
import {
  suppressKeyboardEventClearCells,
  suppressKeyboardEventDisableEnterOnEdit,
} from 'labstep-web/core/DataGrid/CellEditor/utils';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import {
  getEditable,
  setValue,
} from 'labstep-web/core/DataGrid/utils';
import { OrderRequest } from 'labstep-web/models/order-request.model';
import {
  PurchaseOrder,
  PurchaseOrderStatus,
} from 'labstep-web/models/purchase-order.model';
import { objectOrFunction } from 'labstep-web/services/react.service';
import React from 'react';

const colDefEntityLinkPurchaseOrder = colDefEntityLink<OrderRequest>({
  getNestedEntity: (params) => params.data.purchase_order,
  placeholder: 'Select',
});

export const colDefPurchaseOrder: ColDef<OrderRequest> = {
  ...colDefEntityLinkPurchaseOrder,
  colId: 'purchase_order_name',
  headerComponentParams: (params) => ({
    ...objectOrFunction(
      colDefEntityLinkPurchaseOrder.headerComponentParams,
      params,
    ),
    icon: ICONS.purchase_order.primary,
  }),
  headerName: PurchaseOrder.getHumanReadableEntityName(false, true),
  cellEditor: (params) => (
    <DataGridCellEditorSearchSelect
      {...params}
      props={{
        entity: params.data,
        entityName: PurchaseOrder.entityName,
        params: {
          status: PurchaseOrderStatus.open,
        },
        getBody: (value) => ({
          purchase_order_id: value?.id || null,
        }),
      }}
    />
  ),
  cellEditorPopup: true,
  suppressKeyboardEvent: (params) =>
    suppressKeyboardEventDisableEnterOnEdit(params) ||
    suppressKeyboardEventClearCells(params),
  editable: getEditable(),
  valueGetter: (params) => params.data.purchase_order?.idAttr || null,
  valueSetter: (params) =>
    setValue('purchase_order_id', params.newValue, {
      entityName: params.data.entityName,
      id: params.data.id,
    }),
};

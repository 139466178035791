import colDefActionMenu from 'labstep-web/components/Entity/DataGrid/colDefs/actionMenu';
import ResourceItemActionDuplicate from 'labstep-web/components/ResourceItem/Action/Duplicate';
import ResourceItemActionDelete from 'labstep-web/components/ResourceItem/Action/Delete';
import { ResourceItem } from 'labstep-web/models/resource-item.model';
import React from 'react';

export const colDefResourceItemActionMenu =
  colDefActionMenu<ResourceItem>((params) => (
    <>
      <ResourceItemActionDuplicate
        resourceItem={params.data}
        noRedirect
      />
      <ResourceItemActionDelete resourceItem={params.data} />
    </>
  ));

import { IFilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { PostFilterDeleted } from 'labstep-web/services/postFilter/filters/deleted';
import React from 'react';

export const FilterDeletedActivePost: React.FC<
  IFilterPostActiveLabelProps
> = ({ node, onRemoveClick }) => {
  if (!PostFilterDeleted.isNodeActive(node)) {
    return null;
  }
  return (
    <RemoveLabel
      icon="trash"
      name="Deleted"
      onRemoveClick={onRemoveClick}
    />
  );
};

export default FilterDeletedActivePost;

import { PostFilterNodeType } from 'labstep-web/services/query-parameter.service';
import { PostFilterAssignedTo } from 'labstep-web/services/postFilter/filters/assignedTo';
import { PostFilterAuthor } from 'labstep-web/services/postFilter/filters/author';

/**
 * Get the author or assignee guid from a post filter node
 * @param node Post filter node
 * @returns Guid and type (author or assignee)
 */
export const getAuthorOrAssigneeGuid = (
  node: PostFilterNodeType,
):
  | {
      guid: string;
      type: 'author' | 'assignee';
    }
  | Record<string, never> => {
  let type: 'assignee' | 'author';
  let guid: string | undefined;
  const authorGuidAttribute =
    PostFilterAuthor.getAttributeValues(node).guid;
  const assigneeGuidAttribute =
    PostFilterAssignedTo.getAttributeValues(node).guid;
  if (authorGuidAttribute) {
    guid = authorGuidAttribute.value;
    type = 'author';
  } else if (assigneeGuidAttribute) {
    guid = assigneeGuidAttribute.value;
    type = 'assignee';
  } else {
    return {};
  }
  return { guid, type };
};

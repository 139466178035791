/**
 * Labstep
 *
 * @module core/Text/Helper
 * @desc Helper Text
 */
import React from 'react';
import Icon from 'labstep-web/core/Icon';
import styles from './styles.module.scss';
import { ITextHelperProps } from './types';

const TextHelper: React.FC<ITextHelperProps> = ({ children }) => (
  <div className={styles.textHelper}>
    <Icon name="info circle" />
    {children}
  </div>
);

export default TextHelper;

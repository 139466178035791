import EntityLink from 'labstep-web/components/Entity/Link';
import EntityTable from 'labstep-web/components/Entity/Table';
import { Resource } from 'labstep-web/models/resource.model';
import React from 'react';
import ResourceTemplateActionMenu from '../Action/Menu';
import { Action } from '../Action/Menu/types';
import { IResourceTemplateTableProps } from './types';

const ResourceTemplateTable: React.FC<
  IResourceTemplateTableProps
> = ({ resourceTemplates, ...rest }) => {
  return (
    <EntityTable
      id="resource-category-table"
      entities={resourceTemplates}
      primaryColumn={{
        header: 'Name',
        content: (resource: Resource) => (
          <EntityLink entity={resource} />
        ),
        cellProps: { style: { flex: 3 } },
      }}
      actionMenu={({ entity }) => (
        <ResourceTemplateActionMenu
          resourceTemplate={entity as Resource}
          actions={[
            Action.share,
            Action.duplicate,
            Action.transfer_ownership,
            Action.delete,
          ]}
        />
      )}
      {...rest}
    />
  );
};

export default ResourceTemplateTable;

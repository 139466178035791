/**
 * Labstep
 *
 */

import React from 'react';
import classnames from 'classnames';
import { Resizable } from 're-resizable';
import { ISplitViewProps } from './types';
import styles from './styles.module.scss';

export const SplitView: React.FC<ISplitViewProps> = ({
  left,
  right,
  initialWidth,
  minLeftWidth,
  className,
  onChange,
}) => (
  <div className={classnames(styles.splitView, className)}>
    <Resizable
      className={styles.leftPane}
      defaultSize={{
        width: right ? initialWidth : '100%',
        height: 'auto',
      }}
      minWidth={right ? minLeftWidth : '100%'}
      onResizeStop={(e): void => {
        if ('clientX' in e) {
          onChange(e.clientX);
        } else if ('changedTouches' in e && e.changedTouches.length) {
          onChange(e.changedTouches[0].clientX);
        }
      }}
      enable={{
        top: false,
        right: !!right,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
    >
      {left}
    </Resizable>
    {right && (
      <div data-testid="right-pane" className={styles.rightPane}>
        {right}
      </div>
    )}
  </div>
);

export default SplitView;

import RemoveLabel from 'labstep-web/core/Label/Remove';
import React from 'react';
import { IFilterDeletedActiveProps } from './types';

export const FilterDeletedActive: React.FC<
  IFilterDeletedActiveProps
> = ({ clearParams, searchParams }) => {
  if (searchParams.is_deleted || searchParams.is_user_deleted) {
    return (
      <RemoveLabel
        icon={searchParams.is_deleted ? 'trash' : 'ban'}
        name={searchParams.is_deleted ? 'Deleted' : 'Disabled'}
        onRemoveClick={
          clearParams &&
          ((): void => clearParams(['is_deleted', 'is_user_deleted']))
        }
      />
    );
  }
  return null;
};

export default FilterDeletedActive;

import { IFlexProps } from 'labstep-web/core/Flex/types';
import React from 'react';
import FlexView from 'react-flexview';

export const Flex: React.FC<IFlexProps> = ({
  spacing = 'none',
  style,
  ...rest
}) => {
  const spacingStyles = {
    none: {},
    gap: { gap: '10px' },
    between: { justifyContent: 'space-between' },
    around: { justifyContent: 'space-around' },
  };

  return (
    <FlexView
      style={{ ...spacingStyles[spacing], ...style }}
      {...rest}
    />
  );
};

export default Flex;

import EntityUserRoleRequirementActionMenu from 'labstep-web/components/EntityUserRoleRequirement/Action/Menu';
import EntityUserRoleRequirementActionSignatureRequirement from 'labstep-web/components/EntityUserRoleRequirement/Action/SignatureRequirement';
import EntityUserRoleRequirementFormShowEditAutomation from 'labstep-web/components/EntityUserRoleRequirement/Form/ShowEdit/Automation';
import EntityUserRoleRequirementFormShowEditEntityUserRole from 'labstep-web/components/EntityUserRoleRequirement/Form/ShowEdit/EntityUserRole';
import EntityUserRoleRequirementFormShowEditNumberRequired from 'labstep-web/components/EntityUserRoleRequirement/Form/ShowEdit/NumberRequired';
import SignatureRequirementFormShowEditDaysToSign from 'labstep-web/components/SignatureRequirement/Form/ShowEdit/DaysToSign';
import SignatureRequirementFormShowEditRejectEntityState from 'labstep-web/components/SignatureRequirement/Form/ShowEdit/RejectEntityState';
import SignatureRequirementFormShowEditStatement from 'labstep-web/components/SignatureRequirement/Form/ShowEdit/Statement';
import Table from 'labstep-web/core/Table';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import { EntityState } from 'labstep-web/models/entity-state.model';
import { EntityUserRoleRequirement } from 'labstep-web/models/entity-user-role-requirement.model';
import React from 'react';
import styles from './styles.module.scss';
import { IEntityUserRoleRequirementsTableProps } from './types';

export const EntityUserRoleRequirementTable: React.FC<{
  entityUserRoleRequirement: EntityUserRoleRequirement;
  entityStateWorkflow: EntityStateWorkflow;
  entityState: EntityState;
}> = ({
  entityUserRoleRequirement,
  entityStateWorkflow,
  entityState,
}) => {
  const signatureRequirement =
    entityUserRoleRequirement.signature_requirement;
  return (
    <Table key={entityUserRoleRequirement.id}>
      <Table.Body>
        <Table.Row>
          <Table.Cell width={3}>
            <b>
              {entityUserRoleRequirement.number_required}{' '}
              {entityUserRoleRequirement.entity_user_role.name}
            </b>
          </Table.Cell>
          <Table.Cell>
            <div className={styles.right}>
              <EntityUserRoleRequirementActionSignatureRequirement
                entityUserRoleRequirement={entityUserRoleRequirement}
              />
              <EntityUserRoleRequirementActionMenu
                entityUserRoleRequirement={entityUserRoleRequirement}
              />
            </div>
          </Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell width={3}>Role</Table.Cell>
          <Table.Cell>
            <EntityUserRoleRequirementFormShowEditEntityUserRole
              entityUserRoleRequirement={entityUserRoleRequirement}
            />
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell width={3}>Auto-Assign</Table.Cell>
          <Table.Cell>
            <EntityUserRoleRequirementFormShowEditAutomation
              entityState={entityState}
              entityUserRoleRequirement={entityUserRoleRequirement}
              entityStateWorkflowId={entityStateWorkflow.id}
            />
          </Table.Cell>
        </Table.Row>
        {entityUserRoleRequirement.entity_user_role.name !==
          'Author' && (
          <Table.Row>
            <Table.Cell width={3}>No. Required</Table.Cell>
            <Table.Cell>
              <EntityUserRoleRequirementFormShowEditNumberRequired
                entityUserRoleRequirement={entityUserRoleRequirement}
              />
            </Table.Cell>
          </Table.Row>
        )}

        {signatureRequirement && (
          <>
            <Table.Row>
              <Table.Cell width={3}>Default Statement</Table.Cell>
              <Table.Cell>
                <SignatureRequirementFormShowEditStatement
                  signatureRequirement={signatureRequirement}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>No. of Days to Sign</Table.Cell>
              <Table.Cell>
                <SignatureRequirementFormShowEditDaysToSign
                  signatureRequirement={signatureRequirement}
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width={3}>On Reject</Table.Cell>
              <Table.Cell>
                <SignatureRequirementFormShowEditRejectEntityState
                  entityStates={entityStateWorkflow.entity_states}
                  entityState={entityState}
                  signatureRequirement={signatureRequirement}
                />
              </Table.Cell>
            </Table.Row>
          </>
        )}
      </Table.Body>
    </Table>
  );
};

const EntityUserRoleRequirementsTable: React.FC<
  IEntityUserRoleRequirementsTableProps
> = ({
  entityUserRoleRequirements,
  entityStateWorkflow,
  entityState,
}) => (
  <div data-testid="entity-user-role-requirements-table">
    {entityUserRoleRequirements.map((entityUserRoleRequirement) => (
      <EntityUserRoleRequirementTable
        key={entityUserRoleRequirement.id}
        entityUserRoleRequirement={entityUserRoleRequirement}
        entityStateWorkflow={entityStateWorkflow}
        entityState={entityState}
      />
    ))}
  </div>
);

export default EntityUserRoleRequirementsTable;

import { Field } from 'labstep-web/components/ResourceItemExport/Table/types';

export const formatFields = (activeFields: Field[]) => {
  return activeFields.map((field) => {
    if (
      field.id === 'resource_item_metadata' ||
      field.id === 'resource_metadata'
    ) {
      return {
        type: field.id,
        label: field.name,
      };
    }
    return {
      type: 'system',
      label: field.id,
    };
  });
};

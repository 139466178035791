/**
 * Labstep
 *
 * @module components/EntityStateWorkflow/Action/ConfigureWorkflow
 * @desc Search tags
 */
import { ModalDefault as Modal } from 'labstep-web/core/Modal/Default';
import { ReadOnMountHOC } from 'labstep-web/hoc/ReadOnMount';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { useModalRoute } from 'labstep-web/hooks/modalRoute';
import { ModalRouteStateType } from 'labstep-web/hooks/modalRoute/types';
import { EntityStateWorkflow } from 'labstep-web/models/entity-state-workflow.model';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Content from './Content';

export const ENTITY_STATE_WORKFLOW_CONFIGURE_ROUTE_ID =
  'entity-state-workflow-configure';

export const EntityStateWorkflowActionConfigureWorkflowModal: React.FC =
  () => {
    const hasSignatureWorkflowFeatureEnabled =
      useHasFeatureFlagEnabled('signature_workflow');

    const { open, toggleModal } = useModalRoute(
      ENTITY_STATE_WORKFLOW_CONFIGURE_ROUTE_ID,
    );

    const [entityId, setEntityId] = useState(null);
    const { state } = useLocation<ModalRouteStateType>();
    useEffect(() => {
      if (state?.entityStateWorkflowId) {
        setEntityId(state?.entityStateWorkflowId);
      }
    }, [state?.entityStateWorkflowId]);
    if (!hasSignatureWorkflowFeatureEnabled) {
      return null;
    }
    return (
      <Modal
        size="fullscreen"
        dataTestId="configure-workflow-modal"
        header="Configure Workflow"
        modalHocProps={{ open, toggleModal }}
        content={() =>
          entityId && (
            <ReadOnMountHOC
              type="cursor"
              entityName={EntityStateWorkflow.entityName}
              params={{ id: entityId }}
            >
              {({ entities }) => (
                <Content entityStateWorkflow={entities[0]} />
              )}
            </ReadOnMountHOC>
          )
        }
      />
    );
  };

import { ICellEditor, ICellEditorParams } from 'ag-grid-community';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import './styles.scss';

export class PrecursorCellEditor implements ICellEditor {
  private eInput!: HTMLInputElement;

  private container!: HTMLElement;

  private prompt!: HTMLElement | null;

  init(params: ICellEditorParams): void {
    this.container = document.createElement('div');
    this.container.className = 'container';
    if (params.data.custom_identifier) {
      this.prompt = document.createElement('span');
      this.prompt.className = 'prompt';
      this.prompt.innerText = `${params.data.custom_identifier} - `;
      this.container.appendChild(this.prompt);
    }

    this.eInput = document.createElement('input');
    this.eInput.className = 'cell-input';
    this.eInput.value = params.value;
    this.eInput.placeholder = `Give your ${getHumanReadableEntityName(
      params.data.entityName,
      false,
      false,
    )} a name`;
    this.container.appendChild(this.eInput);
  }

  getGui(): HTMLElement {
    return this.container;
  }

  afterGuiAttached(): void {
    this.eInput.focus();
    const length = this.eInput.value ? this.eInput.value.length : 0;
    this.eInput.setSelectionRange(length, length); // move cursor to end
  }

  getValue(): any {
    return this.eInput.value;
  }
}

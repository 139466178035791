import {
  convertAmountUnitToAmount,
  convertAmountUnitToUnit,
} from 'labstep-web/services/amount-unit.service';

/**
 * Validate and process value if numeric
 * Returns false if invalid
 * @param value Value
 * @returns Numeric value or false
 */
export const validateNumeric = (
  value: unknown,
): string | false | null => {
  if (value === null) {
    return value as null;
  }
  // '10 g' should become '10'
  const amount = convertAmountUnitToAmount(String(value));
  if (amount === false) {
    return false;
  }
  return amount;
};

/**
 * Validate and process value if unit
 * Returns false if invalid
 * @param value Value
 * @returns Unit or false
 */
export const validateUnit = (
  value: unknown,
): string | false | null => {
  if (value === null) {
    return value as null;
  }
  // '10 g' should become 'g'
  const unit = convertAmountUnitToUnit(String(value));
  if (unit === false) {
    return false;
  }
  return unit;
};

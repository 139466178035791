import { File } from 'labstep-web/models/file.model';
import { IActionMenuProps } from 'labstep-web/core/Action/Menu/types';

// eslint-disable-next-line no-shadow
export enum Action {
  download = 'download',
  preview = 'preview',
  delete = 'delete',
}

export interface IFileActionMenuProps {
  file: File;
  actions: Action[];
  type?: IActionMenuProps['type'];
}

import { ExperimentWorkflow } from 'labstep-web/models/experiment-workflow.model';

export interface IWorkflowStatusIndicatorProps {
  experimentWorkflow: ExperimentWorkflow;
}

// eslint-disable-next-line no-shadow
export enum WorkflowStatusIndicatorSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

import colDefAmount from 'labstep-web/components/Entity/DataGrid/colDefs/amount';
import { ICONS } from 'labstep-web/constants/icons';

export const colDefResourceItemAmount = colDefAmount({
  columnProps: {
    colId: 'quantity',
    headerName: 'Amount Remaining',
    headerComponentParams: {
      icon: ICONS.resource_item.info.quantity,
    },
  },
  updateUnit: true,
});

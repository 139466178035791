import { ResourceItem } from 'labstep-web/models/resource-item.model';
import { Resource } from 'labstep-web/models/resource.model';

export const getParams = (
  entity: Resource | ResourceItem,
  type: 'input' | 'output',
  extraParams?: Record<string, unknown>,
) => {
  const params: Record<string, unknown> = {
    has_value: true,
    ...extraParams,
  };

  if (type === 'input') {
    params.is_input = false;
  }

  if (entity instanceof Resource) {
    params[`multiplexing_${type}_resource_id`] = entity.id;
  } else {
    params[`multiplexing_${type}_resource_item_id`] = entity.id;
  }
  return params;
};

import SignatureListEntityState from 'labstep-web/components/Signature/List/EntityState';
import { EntityState } from 'labstep-web/models/entity-state.model';
import React from 'react';
import { IStepAssignProps } from './types';

const StepAssign: React.FC<IStepAssignProps> = ({
  experimentWorkflow,
  nextEntityState,
}) => {
  return (
    <SignatureListEntityState
      entityState={nextEntityState as EntityState}
      experimentWorkflow={experimentWorkflow}
      round={experimentWorkflow.entity_state_round}
      showState={false}
    />
  );
};

export default StepAssign;

import { Filter } from 'labstep-web/components/Filter/Menu/types';

export const bulkActions = ['assign', 'delete', 'share', 'transfer_ownership'];

export const filters = [
  Filter.created_by,
  Filter.assigned_to,
  Filter.date_range,
  Filter.deleted,
];

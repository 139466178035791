/**
 * From: https://github.com/podefr/react-debounce-render/blob/master/src/index.js
 */

import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import hoistNonReactStatics from 'hoist-non-react-statics';

const debounceRender = <P extends { [key: string]: any }>(
  ComponentToDebounce: React.ComponentType<P>,
) => {
  class DebouncedContainer extends Component<P> {
    updateDebounced = debounce(this.forceUpdate, 100);

    shouldComponentUpdate() {
      this.updateDebounced();
      return false;
    }

    componentWillUnmount() {
      this.updateDebounced.cancel();
    }

    render() {
      return <ComponentToDebounce {...this.props} />;
    }
  }

  return hoistNonReactStatics(
    DebouncedContainer,
    ComponentToDebounce,
  );
};

export default debounceRender;

/**
 * Labstep
 *
 * @module components/Device/Image
 * @desc Device image
 */

import React from 'react';
import EntityImageUploadable from 'labstep-web/components/Entity/Image/Uploadable';
import { IDeviceImagUploadableProps } from './types';

const DeviceImageUploadble: React.FC<IDeviceImagUploadableProps> = ({
  device,
  mobile,
}) => <EntityImageUploadable entity={device} mobile={mobile} />;

export default DeviceImageUploadble;

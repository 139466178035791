import { IFilterPostActiveLabelProps } from 'labstep-web/components/Filter/Post/Active/Label/types';
import RemoveLabel from 'labstep-web/core/Label/Remove';
import { dateOnly } from 'labstep-web/services/date.service';
import React from 'react';

export const FilterDateRangeActivePost: React.FC<
  IFilterPostActiveLabelProps
> = ({ onRemoveClick, node }) => {
  const predicates = node.predicates.filter(
    (p) => p.attribute === 'created_at',
  );
  if (!predicates.length) {
    return null;
  }

  const name = predicates
    .map((predicate) => {
      return `${dateOnly(predicate.value as string)}`;
    })
    .join(' - ');

  return (
    <>
      <RemoveLabel name={name} onRemoveClick={onRemoveClick} />
    </>
  );
};

export default FilterDateRangeActivePost;

/**
 * Labstep
 *
 * @module components/UserGroup/Action/Delete
 * @desc Action to delete experiment
 */

import EntityActionDelete from 'labstep-web/components/Entity/Action/Delete';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';
import { IUserGroupDeleteAction } from './types';

export const UserGroupDeleteAction: React.FC<
  IUserGroupDeleteAction
> = ({ userGroup, authenticatedUserEntity }) => (
  <EntityActionDelete
    actionComponentProps={{
      type: 'option',
      icon: 'times',
      text:
        userGroup.user.id === authenticatedUserEntity.id
          ? 'Leave workspace'
          : 'Remove',
    }}
    header={
      userGroup.user.id === authenticatedUserEntity.id
        ? 'Leave workspace'
        : 'Remove user'
    }
    confirmButtonContent={
      userGroup.user.id === authenticatedUserEntity.id
        ? 'Leave'
        : 'Remove'
    }
    confirmMessage={
      userGroup.user.id === authenticatedUserEntity.id
        ? 'Are you sure you want to leave this workspace? Leaving this workspace cannot be undone. To gain access to this workspace again you will need to be re-invited.'
        : `Are you sure you want to remove ${userGroup.user
            ?.name} from this ${getHumanReadableEntityName('group')}`
    }
    entity={userGroup}
  />
);

export default withAuthenticatedUserEntity(UserGroupDeleteAction);

import { getHeightOfRows } from 'labstep-web/core/DataGrid/utils';
import { IEntityDataGridProps } from './types';

const SCROLL_LOAD_HEIGHT_RATIO = 0.5;

/**
 * Call load more fn when reaching end of grid
 * @param loadMore Load more fn
 */
export const loadMoreOnScroll =
  (loadMore: NonNullable<IEntityDataGridProps['loadMore']>) =>
  (
    params: Parameters<
      NonNullable<IEntityDataGridProps['onGridReady']>
    >[0],
  ): void => {
    const verticalPixelRange = params.api.getVerticalPixelRange();
    if (!verticalPixelRange) {
      return;
    }
    const { bottom } = verticalPixelRange;
    const heightOfRows = getHeightOfRows(params);
    if (heightOfRows <= bottom / SCROLL_LOAD_HEIGHT_RATIO) {
      loadMore({
        onSuccess: () => loadMoreOnScroll(loadMore)(params),
      });
    }
  };

import { ICoreFormCreateProps } from 'labstep-web/core/Form/Create/types';
import { MetadataThread } from 'labstep-web/models/metadata-thread.model';
import { Resource } from 'labstep-web/models/resource.model';
import { ICreateAction, IOptions } from 'labstep-web/typings';

export const getOnSubmit =
  ({
    createResourceItemTemplate,
    createMetadata,
    resource,
    options,
  }: {
    createResourceItemTemplate: ICreateAction;
    createMetadata: ICreateAction;
    resource: Resource;
    options?: IOptions;
  }): ICoreFormCreateProps['onSubmit'] =>
  (body): void =>
    createResourceItemTemplate(
      {
        resource_id: resource.id,
        is_template: true,
      },
      {
        onSuccess: ({ response }) => {
          const metadataThreadId =
            response.entities.resource_item[response.result]
              .metadata_thread;
          createMetadata(
            body,
            options,
            MetadataThread.entityName,
            metadataThreadId,
          );
        },
      },
    );

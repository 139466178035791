import { Action } from 'labstep-web/components/ProtocolCollection/Action/Menu/types';

export const actions = [
  Action.add_to_experiment,
  Action.share,
  Action.duplicate,
  Action.print,
  Action.tag,
  Action.delete,
  Action.revoke,
  Action.transfer_ownership,
  Action.add_to_folder,
  Action.remove_from_folder,
  Action.assign,
];

import { EntityUserRoleSelectPanel } from 'labstep-web/components/EntityUserRole/SelectPanel';
import { systemOptions } from 'labstep-web/constants/entity-user-role';
import { withActiveGroup } from 'labstep-web/containers/ActiveGroup';
import { EntityUpdateContainer } from 'labstep-web/containers/Entity/Update';
import { EntityUserRoleRequirement } from 'labstep-web/models/entity-user-role-requirement.model';
import { Group } from 'labstep-web/models/group.model';
import React from 'react';
import { IEntityUserRoleRequirementFormShowEditEntityUserRoleProps } from './types';

export const EntityUserRoleRequirementFormShowEditEntityUserRole: React.FC<
  IEntityUserRoleRequirementFormShowEditEntityUserRoleProps
> = ({ activeGroup, entityUserRoleRequirement }) => {
  return (
    <>
      <EntityUpdateContainer
        entityName={EntityUserRoleRequirement.entityName}
        id={entityUserRoleRequirement.id}
      >
        {({ update }) => (
          <EntityUserRoleSelectPanel
            systemOptions={systemOptions}
            group={activeGroup as Group}
            onBlur={() => {}}
            onChange={(entityUserRole) => {
              if (entityUserRole) {
                update({
                  entity_user_role_id: entityUserRole.id,
                  number_required: 1,
                });
              }
            }}
            entityType="Role"
            defaultSelectedRoleId={
              entityUserRoleRequirement.entity_user_role.id
            }
            openMenuOnFocus={false}
          />
        )}
      </EntityUpdateContainer>
    </>
  );
};

export default withActiveGroup(
  EntityUserRoleRequirementFormShowEditEntityUserRole,
);

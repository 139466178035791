import {
  FieldType,
  IFieldWithoutActionProps,
} from 'labstep-web/core/Form/Reusable/types';
import { EntityState } from 'labstep-web/models/entity-state.model';
import rules from 'labstep-web/services/validation/rules';

export const fieldSignatureRequirementStatement: IFieldWithoutActionProps =
  {
    fieldLabel: 'Statement',
    name: 'statement',
    fieldType: FieldType.TextArea,
    validation: rules.signature_requirement.statement,
    placeholder: `Enter default statement`,
  };

export const fieldSignatureRequirementDaysToSign: IFieldWithoutActionProps =
  {
    fieldLabel: 'Days to sign',
    name: 'days_to_sign',
    fieldType: FieldType.Text,
    validation: rules.signature_requirement.days_to_sign,
    placeholder: `Enter number of days from assignment...`,
  };

export const fieldSignatureRequirementRejectEntityState = (
  entityStates: EntityState[],
): IFieldWithoutActionProps => ({
  name: 'reject_entity_state_id',
  fieldLabel: 'Change Experiment Status To',
  fieldType: FieldType.ReactSelect,
  elementProps: {
    options: entityStates.map((entityState) => ({
      value: entityState.id,
      label: entityState.name,
    })),
  },
  validation: rules.signature_requirement.reject_entity_state,
});

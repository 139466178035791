/**
 * Labstep
 *
 * @module services/ag-grid-export
 * @desc AGGrid Service Export
 */

import { ProcessCellForExportParams } from 'ag-grid-community';
import { isObject } from 'lodash';
import React from 'react';

export class AGGridEntityExportService {
  public static processCellCallback(
    params: ProcessCellForExportParams,
  ) {
    const colDef = params.column.getColDef();
    if (colDef) {
      const { cellRenderer } = colDef;
      if (cellRenderer && typeof cellRenderer === 'function') {
        const cellRendererValue = cellRenderer(params);
        if (!isObject(cellRendererValue)) {
          return cellRendererValue;
        }
        if (React.isValidElement(cellRendererValue)) {
          const props = cellRendererValue.props as { label?: string };
          if (props.label) {
            return props.label;
          }
        }
      }
    }
    return params.value || '';
  }

  public static defaultCsvExportParams = {
    processCellCallback:
      AGGridEntityExportService.processCellCallback,
  };

  public static defaultExcelExportParams = {
    processCellCallback:
      AGGridEntityExportService.processCellCallback,
  };
}

/**
 * Labstep.
 *
 * @module services/window
 * @desc Proxy around browser window
 */

import {
  IOrganization,
  ORGANIZATIONS,
} from 'labstep-web/config/saml';

export class WindowService {
  window: Window;

  constructor(window: Window) {
    this.window = window;
  }

  detectOrganization(): IOrganization | null {
    return (
      ORGANIZATIONS.find(
        (organization) =>
          this.window &&
          this.window.location &&
          this.window.location.host &&
          organization.domain &&
          this.window.location.host.includes(organization.domain),
      ) || null
    );
  }

  getComputedStyle(id: string) {
    if (this.window) {
      const element =
        this.document && this.document.getElementById(id);
      if (!element) {
        return null;
      }
      return this.window.getComputedStyle(element);
    }

    return null;
  }

  getElementById(id: string) {
    if (this.window && this.window.document) {
      return this.window.document.getElementById(id);
    }

    return null;
  }

  clearLocalStorage() {
    if (this.window && this.window.localStorage) {
      this.window.localStorage.clear();
    }
  }

  setLocation(url: string) {
    if (this.window && this.window.location) {
      this.window.location.href = url.replace('http://', 'https://');
    }

    return null;
  }

  open(url: string) {
    if (this.window && this.window.open) {
      return this.window.open(url.replace('http://', 'https://'));
    }

    return null;
  }

  createObjectURL(data: Blob): string | null {
    if (this.window && this.window.URL) {
      return this.window.URL.createObjectURL(data);
    }
    return null;
  }

  reload(): void {
    if (
      this.window &&
      this.window.location &&
      this.window.location.reload
    ) {
      this.window.location.reload();
    }
  }

  alert(msg: string): void {
    if (this.window && this.window.alert) {
      this.window.alert(msg);
    }
  }

  get document() {
    if (this.window && this.window.document) {
      return this.window.document;
    }

    return null;
  }

  get gapi() {
    if (this.window && this.window.gapi) {
      return this.window.gapi;
    }

    return null;
  }

  get viewportSize() {
    if (this.window) {
      return {
        width: this.window.innerWidth,
        height: this.window.innerHeight,
      };
    }

    return null;
  }
}

export const windowService = new WindowService(window);

import Icon from 'labstep-web/core/Icon';
import React from 'react';
import EntityStateWorkflowActionMenu from '../../Action/Menu';
import { Action } from '../../Action/Menu/types';
import styles from './styles.module.scss';
import { IEntityStateWorkflowSearchSelectOptionProps } from './types';

export const EntityStateWorkflowSearchSelectOption: React.FC<
  IEntityStateWorkflowSearchSelectOptionProps
> = ({ option, experimentWorkflow }) => (
  <div className={styles.groupOption}>
    {option.icon && <Icon name={option.icon} />}
    <div>
      <p className={styles.optionLabel}>{option.label}</p>
      <p className={styles.optionDescription}>{option.description}</p>
    </div>
    <div>
      {option.value !== 'entity' &&
        option.value !==
          experimentWorkflow.entity_state_workflow?.id && (
          <EntityStateWorkflowActionMenu
            entityStateWorkflow={option.entity}
            actions={[Action.view, Action.configure, Action.delete]}
          />
        )}
    </div>
  </div>
);

export default EntityStateWorkflowSearchSelectOption;

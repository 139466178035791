/**
 * Labstep.
 *
 * @module epics/multiplexing
 * @desc Redux epics for multiplexing
 */

import { Action } from 'labstep-web/models/action.model';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { getIdAttribute } from 'labstep-web/services/schema/helpers';
import { StateObservable } from 'redux-observable';
import { Observable, concat, from, of } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { LabstepReduxState } from '../types';

/**
 * Child entities of protocol condition.
 */
const CHILD_ENTITY_NAMES = [
  Metadata.entityName,
  ProtocolValue.entityName,
];

/**
 * Cascade delete child entities of protocol condition.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const protocolConditionCascadeDeleteEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_UPDATE_PROTOCOL_CONDITION' &&
        action.meta.body.deleted_at,
    ),
    mergeMap((action: Action) => {
      const actions: Action[] = [];
      CHILD_ENTITY_NAMES.forEach((entityName) => {
        const childEntities = Object.values(
          state$.value.entities[entityName].byId,
        ).filter(
          (e) => e.protocol_condition_guid === action.meta.identifier,
        );

        childEntities.forEach((e) =>
          actions.push({
            type: `SUCCESS_UPDATE_${entityName.toUpperCase()}`,
            meta: {
              entityName,
              identifier: e[getIdAttribute(entityName)],
              body: { deleted_at: action.meta.body.deleted_at },
            },
            payload: {
              result: e[getIdAttribute(entityName)],
            },
          }),
        );
      });

      return from(actions);
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_PROTOCOL_CONDITION_CASCADE_DELETE',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Cascade create child entities of protocol condition.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const protocolConditionCascadeCreateEpic = (
  action$: Observable<Action>,
  state$: StateObservable<LabstepReduxState>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_CREATE_PROTOCOL_CONDITION',
    ),
    mergeMap((action: Action) => {
      const actions: Action[] = [];
      CHILD_ENTITY_NAMES.forEach((entityName) => {
        const childEntities = Object.values(
          state$.value.entities[entityName].byId,
        ).filter((e) =>
          Array.isArray(action.payload.result)
            ? action.payload.result.includes(
                e.protocol_condition_guid,
              )
            : action.payload.result === e.protocol_condition_guid,
        );

        childEntities.forEach((e) =>
          actions.push({
            type: `SUCCESS_CREATE_${entityName.toUpperCase()}`,
            meta: {
              entityName,
              identifier: e[getIdAttribute(entityName)],
              body: {
                variable_template_guid: e.variable_template_guid,
              },
            },
            payload: {
              result: e[getIdAttribute(entityName)],
            },
          }),
        );
      });

      return from(actions);
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_PROTOCOL_CONDITION_CASCADE_CREATE',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * Labstep
 *
 */

import React from 'react';
import EntityActionUpdateCheckbox from 'labstep-web/components/Entity/Action/Update/Checkbox';
import { IGroupActionSetIsInviteOnlyProps } from './types';

export const GroupActionSetIsInviteOnly: React.FC<
  IGroupActionSetIsInviteOnlyProps
> = ({ group }) => (
  <EntityActionUpdateCheckbox
    entity={group}
    field={'is_invite_only' as const}
    toggle
    optimistic
  />
);

export default GroupActionSetIsInviteOnly;

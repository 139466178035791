import { Column } from 'ag-grid-community';
import { IEntityDataGridToolPanelColumnsSectionItemProps } from './Item/types';

// eslint-disable-next-line no-shadow
export enum SectionType {
  visible = 'visible',
  hidden = 'hidden',
}
export interface IEntityDataGridToolPanelColumnsSectionProps {
  columns: Column[];
  section: SectionType;
  onToggleColumnVisible: IEntityDataGridToolPanelColumnsSectionItemProps['onToggleColumnVisible'];
  onSortColumns: (columns: Column[]) => void;
}

export type ColumnWithId = Column & {
  getId: () => string;
};

export type ItemWithColumn = {
  id: string;
  column: ColumnWithId;
};

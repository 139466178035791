/**
 * Labstep
 *
 * @module components/Group/Action/Create
 * @desc Action to create group
 */

import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import Button from 'labstep-web/core/Button';
import { Group } from 'labstep-web/models/group.model';
import {
  capitalize,
  getHumanReadableEntityName,
} from 'labstep-web/services/i18n.service';
import React from 'react';
import { UserOrganizationType } from 'labstep-web/models/user-organization.model';
import { useHasExceededLimit } from 'labstep-web/hooks/limits/limits';
import { Limits, LimitTypes } from 'labstep-web/constants/limits';
import Modal from 'labstep-web/core/Modal/Default';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import AcademicActionUpgrade from 'labstep-web/components/Academic/Action/Upgrade';
import { ICONS } from 'labstep-web/constants/icons';
import styles from './styles.module.scss';
import { IGroupActionCreateProps } from './types';

export const GroupActionCreate: React.FC<IGroupActionCreateProps> = ({
  authenticatedUserEntity,
}) => {
  const { userOrganization } = authenticatedUserEntity;

  if (
    userOrganization &&
    [
      UserOrganizationType.guest,
      UserOrganizationType.viewer,
    ].includes(userOrganization.type)
  ) {
    return null;
  }

  if (
    userOrganization &&
    userOrganization.type === 'member' &&
    !userOrganization.organization.is_allow_member_create_group
  ) {
    return null;
  }

  const showGroupLimitWarning = useHasExceededLimit(
    authenticatedUserEntity.group_count,
    LimitTypes.MAX_GROUPS_PER_USER,
  );

  const buttonText = `New ${getHumanReadableEntityName(
    Group.entityName,
    false,
    true,
  )}`;

  return showGroupLimitWarning ? (
    <Modal
      size="small"
      header={`${capitalize(
        getHumanReadableEntityName(Group.entityName),
      )} Limit Reached`}
      headerColor="red"
      viewComponent={({ toggleModal }) => (
        <ActionComponent
          type="button"
          onClick={toggleModal}
          icon="share alternate"
          text={buttonText}
        />
      )}
      content={
        <div>
          <p>
            You cannot invite more users as there is a limit of{' '}
            {Limits[LimitTypes.MAX_GROUPS_PER_USER]} users per
            workspace on the Free Tier.
          </p>
          <p>
            Upgrade to Labstep Academia Plus for up to 50 users or
            Academic Institute for unlimited users and workspaces.
          </p>
        </div>
      }
      actions={
        <AcademicActionUpgrade
          actionComponentProps={{
            type: 'button',
            icon: ICONS.upgrade.primary,
            text: 'Upgrade',
            showIcon: true,
            elementProps: {
              color: 'green',
              size: 'large',
              style: { width: '100%' },
            },
          }}
        />
      }
    />
  ) : (
    <Button
      size="small"
      route={{ to: 'group_create' }}
      primary
      className={styles.button}
    >
      {buttonText}
    </Button>
  );
};

export default withAuthenticatedUserEntity(GroupActionCreate);

/**
 * Labstep.
 *
 * @module epics/websocket-room
 * @desc Redux epic for websocket room
 */

import { Action } from 'labstep-web/models/action.model';
import { websocketService } from 'labstep-web/services/websocket.service';
import { selectWebsocketRoom } from 'labstep-web/state/selectors/websocket';
import { StateObservable } from 'redux-observable';
import { Observable, concat, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';

/**
 * When location changes, join a new room.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onLocationChangeUpdateRoomActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'LOCATION_CHANGE' &&
        action.meta &&
        action.meta.path,
    ),
    mergeMap((action: Action) => {
      const currentRoom: any = selectWebsocketRoom(state$.value);

      if (
        action.meta &&
        action.meta.path &&
        action.meta.path.startsWith('/experiment-workflow/')
      ) {
        return of({
          type: 'JOIN_EXPERIMENT_WORKFLOW_ROOM',
        });
      }
      if (currentRoom === null) {
        return of({
          type: 'JOIN_ROOM_ALREADY_JOINED',
        });
      }
      return of({
        type: 'JOIN_ROOM',
        meta: {
          room: null,
        },
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_LOCATION_CHANGE_UPDATE_ROOM',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When joining a room, send a message to the websocket.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onJoinRoomActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'JOIN_ROOM'),
    tap((action: Action) => {
      websocketService.joinRoom(action.meta.room);
    }),
    ignoreElements(),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_LOCATION_CHANGE_UPDATE_ROOM',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When websocket connects, join room if any.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @param  {StateObservable<LabstepReduxState>} state$
 * @return {Observable<Action>}
 */
export const onWebsocketConnectJoinRoomActionEpic = (
  action$: Observable<Action>,
  state$: StateObservable<any>,
): Observable<Action> =>
  action$.pipe(
    filter((action: Action) => action.type === 'WEBSOCKET_CONNECT'),
    mergeMap(() => {
      const room = selectWebsocketRoom(state$.value);

      return of({
        type: 'JOIN_ROOM',
        meta: {
          room,
        },
      });
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_WEBSOCKET_CONNECT_JOIN_ROOM',
          payload: err,
        }),
        source$,
      ),
    ),
  );

/**
 * When loading an experiment workflow, join room using its guid.
 *
 * @function
 * @param  {Observable<Action>} action$
 * @return {Observable<Action>}
 */
export const onWebsocketReadExperimentWorkflowActionEpic = (
  action$: Observable<Action>,
): Observable<Action> =>
  action$.pipe(
    filter(
      (action: Action) =>
        action.type === 'SUCCESS_READ_EXPERIMENT_WORKFLOW_RAW_OUTPUT',
    ),
    map((action: Action) => {
      return {
        type: 'JOIN_ROOM',
        meta: {
          room: `experiment_workflow:${action.payload.guid}`,
        },
      };
    }),
    catchError((err, source$: Observable<Action>) =>
      concat(
        of({
          type: 'EPIC_FAIL_READ_WEBSOCKET_EXPERIMENT_WORKFLOW',
          payload: err,
        }),
        source$,
      ),
    ),
  );

import { StripeContainer } from 'labstep-web/containers/Stripe';
import ActionComponent from 'labstep-web/core/Action/Component';
import { windowService } from 'labstep-web/services/window.service';
import React from 'react';

export const OrganizationPlanActionBillingPortal: React.FC = () => (
  <StripeContainer>
    {({
      createStripeBillingPortalSession,
      createBillingPortalSessionStatus,
    }) => (
      <ActionComponent
        type="button"
        status={createBillingPortalSessionStatus}
        onClick={() =>
          createStripeBillingPortalSession(
            {
              return_url: window.location.href,
            },
            {
              onSuccess: ({ response }) => {
                windowService.setLocation(response.url);
              },
            },
          )
        }
        elementProps={{
          basic: true,
        }}
        text="Edit Payment Method"
      />
    )}
  </StripeContainer>
);

export default OrganizationPlanActionBillingPortal;

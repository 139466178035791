import colDefEntityUser from 'labstep-web/components/Entity/DataGrid/colDefs/entityUser';
import { ColDef } from 'labstep-web/core/DataGrid/types';
import { Resource } from 'labstep-web/models/resource.model';
import { getResourceMetadataColDefs } from 'labstep-web/components/Resource/DataGrid/colDefs/metadata';
import colDefAvailableItems from 'labstep-web/components/Resource/DataGrid/colDefs/availableItems';
import { colDefResourceNameNoLink } from 'labstep-web/components/Resource/DataGrid/colDefs/name/noLink';
import { colDefResourceTemplate } from 'labstep-web/components/Resource/DataGrid/colDefs/template';

export default (
  resourceTemplate?: Resource | null,
): ColDef<Resource>[] => {
  const colDefs = resourceTemplate
    ? [
        colDefResourceNameNoLink,
        colDefAvailableItems,
        ...getResourceMetadataColDefs(resourceTemplate),
        colDefEntityUser,
      ]
    : [
        colDefResourceNameNoLink,
        colDefResourceTemplate,
        colDefAvailableItems,
        colDefEntityUser,
      ];
  return colDefs;
};

import Flex from 'labstep-web/core/Flex';
import LabelToggle from 'labstep-web/core/Label/Toggle';
import React, { useState } from 'react';
import SignatureSignRejectFormCreate from '../Form/CreateSignReject';
import { SignatureTypeAction } from '../SignAndRejectButtons/types';
import { ISignatureModalProps } from './types';

export const SignatureModal: React.FC<ISignatureModalProps> = ({
  experimentWorkflow,
  signatureTypeAction,
  toggleModal,
}) => {
  const [choice, setChoice] = useState<SignatureTypeAction>(
    signatureTypeAction,
  );

  return (
    <Flex data-testid="signature-modal" column>
      <Flex hAlignContent="center">
        <LabelToggle
          items={[
            {
              id: '1',
              icon: 'close',
              iconColor: 'red',
              text: 'Reject',
            },
            {
              id: '2',
              iconColor: 'green',
              icon: 'signup',
              text: 'Sign',
            },
          ]}
          onClick={(item) => {
            setChoice(
              item.id === '1'
                ? SignatureTypeAction.reject
                : SignatureTypeAction.sign,
            );
          }}
          activeItemId={
            choice === SignatureTypeAction.reject ? '1' : '2'
          }
        />
      </Flex>

      <SignatureSignRejectFormCreate
        experimentWorkflow={experimentWorkflow}
        signatureTypeAction={choice}
        toggleModal={toggleModal}
      />
    </Flex>
  );
};

export default SignatureModal;

import isEmpty from 'lodash/isEmpty';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';

export const isValidNewOption = (
  inputValue,
  selectValue,
  selectOptions,
  labelKey,
  caseSensitive = true,
  allowCreateWithEmptyInput,
  creatable,
) => {
  if (inputValue.trim().length === 0) {
    if (allowCreateWithEmptyInput && creatable) {
      return true;
    }
    return false;
  }
  return !selectOptions.some((option) => {
    if (!caseSensitive) {
      return (
        option[labelKey].toLowerCase() === inputValue.toLowerCase()
      );
    }
    return option[labelKey] === inputValue;
  });
};

export const getPlaceholder = (
  entityName: string,
  creatable?: boolean,
  isTemplate?: boolean,
): string => {
  return `Search your ${getHumanReadableEntityName(
    entityName,
    true,
    false,
    isTemplate,
  )}${creatable ? ' or create a new one' : ''}...`;
};

export const noOptionsMessage = (
  entityName,
  searchParams,
  noEntitiesMessage,
  creatable,
  minimumCharacters,
  searchKey,
  isTemplate: boolean,
) => {
  if (isEmpty(searchParams) && creatable) {
    const humanReadableEntityName = getHumanReadableEntityName(
      entityName,
      false,
      false,
      isTemplate,
    );

    return `Type something to create a ${humanReadableEntityName}`;
  }

  if (
    minimumCharacters &&
    (!searchParams[searchKey] ||
      searchParams[searchKey].length < minimumCharacters)
  ) {
    return `Type at least ${minimumCharacters} characters to start searching`;
  }

  if (isEmpty(searchParams) && noEntitiesMessage) {
    return noEntitiesMessage;
  }

  return `Uh oh. No ${getHumanReadableEntityName(
    entityName,
    true,
    false,
    isTemplate,
  )} found`;
};

/**
 * Labstep
 *
 * @module components/OrganizationPlan/Card/Users
 * @desc Organization plan card for users
 */

import OrganizationPlanCard from 'labstep-web/components/OrganizationPlan/Card';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import styles from '../styles.module.scss';
import { OrganizationPlanCardUsersProps } from './types';

export const OrganizationPlanCardUsers: React.FC<
  OrganizationPlanCardUsersProps
> = ({ organization, organizationPlan, history }) => {
  let primaryText: React.ReactNode = organization.seats;
  let noSeatsLeft = false;

  if (organizationPlan) {
    if (
      organizationPlan.quantity &&
      organizationPlan.quantity <= (organization.seats || 0)
    ) {
      noSeatsLeft = true;
    }

    if (noSeatsLeft) {
      primaryText = (
        <>
          <span className={styles.red}>{organization.seats}</span>/
          {organizationPlan.quantity}
        </>
      );
    } else {
      primaryText = `${organization.seats}/${organizationPlan.quantity}`;
    }
  }

  return (
    <OrganizationPlanCard
      icon="users"
      header="Users"
      primary={
        <>
          <span>
            {primaryText}
            {noSeatsLeft ? (
              <span className={`${styles.subText} ${styles.red}`}>
                Contact{' '}
                <a href="mailto:billing@labbstep.com">
                  info@labstep.com
                </a>{' '}
                to increase the User Limit
              </span>
            ) : (
              <span className={styles.subText}> seats used</span>
            )}
          </span>
          {organization.free_seats > 0 && (
            <p className={styles.emphasized}>
              + {organization.free_seats} free users
            </p>
          )}
        </>
      }
      action={
        <>
          <div className={styles.upgrade}>
            <ActionComponent
              type="button"
              text="Manage"
              showIcon
              onClick={() =>
                history.push(
                  navigation.get('organization_show_users', {
                    identifier: organization.identifier,
                  }),
                )
              }
              elementProps={{
                basic: true,
              }}
            />
          </div>
        </>
      }
    />
  );
};

export default withRouter(OrganizationPlanCardUsers);

/**
 * Labstep
 *
 * @module components/UserOrganization/Action/Delete
 */

import { EntityActionDeleteComponent } from 'labstep-web/components/Entity/Action/Delete';
import { withAuthenticatedUserEntity } from 'labstep-web/containers/AuthenticatedUser';
import React from 'react';
import { IUserOrganizationActionDeleteProps } from './types';

export const disableMessage = (
  <div>
    <p>Are you sure you want to disable this user account?</p>
    <ul>
      <li>
        They will no longer be able to access the Labstep platform or
        any content (effective immediately)
      </li>
      <li>
        They will no longer count as a user on your monthly invoice
        (this will take effect in the next billing period)
      </li>
    </ul>
  </div>
);

export const enableMessage = (
  <div>
    <p>Are you sure you want to restore this user account?</p>
    <ul>
      <li>
        They will once again be able to access the Labstep platform
        and any workspaces they were members of (effective
        immediately)
      </li>
      <li>
        They will start counting again as an active user on your
        monthly invoice (effective immediately)
      </li>
    </ul>
  </div>
);

export const UserOrganizationActionDelete: React.FC<
  IUserOrganizationActionDeleteProps
> = ({ userOrganization, authenticatedUserEntity }) => {
  if (authenticatedUserEntity.userOrganization?.type !== 'admin') {
    return null;
  }
  const isActive = userOrganization.user.deleted_at === null;

  return (
    <EntityActionDeleteComponent
      isActive={isActive}
      confirmButtonContent={isActive ? 'Disable' : 'Restore'}
      entityName={userOrganization.user.entityName}
      id={userOrganization.user.id}
      header="Warning"
      actionComponentProps={{
        type: 'option',
        text: `${isActive ? 'Disable' : 'Restore'} User`,
      }}
      iconName={isActive ? 'ban' : 'undo'}
      message={isActive ? disableMessage : enableMessage}
    />
  );
};

export default withAuthenticatedUserEntity(
  UserOrganizationActionDelete,
);

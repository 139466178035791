import { useCallback } from 'react';
import { useDataGridContext } from './context';
import { IDataGridProps } from './types';

/**
 * Hook to update DataGridContext on column change
 * @returns Grid callbacks to update context on column change
 */
export const useUpdateContextOnColumnChange = (): Pick<
  IDataGridProps,
  'onDisplayedColumnsChanged'
> => {
  /** Allows accessing ref through context provider */
  const { setColumns, setColumnState } = useDataGridContext();

  /**
   * Save the column state and columns to the context
   */
  const onColumnStateChanged = useCallback<
    NonNullable<IDataGridProps['onDisplayedColumnsChanged']>
  >(
    (params) => {
      setColumnState?.(params.columnApi?.getColumnState());
      setColumns?.(params.columnApi?.getAllGridColumns());
    },
    [setColumns, setColumnState],
  );

  return {
    onDisplayedColumnsChanged: onColumnStateChanged,
  };
};

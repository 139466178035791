/**
 * Labstep
 *
 */

import ActionComponent from 'labstep-web/core/Action/Component';
import Modal from 'labstep-web/core/Modal/Confirmation';
import React from 'react';
import { updateAmountDeductedAtActionComponentProps } from '../constants';
import { IProtocolValueActionUpdateAmountDeductedAtModalProps } from './types';
import { getHeaderAndMessage } from './utils';

const ProtocolValueActionUpdateAmountDeductedAtModal: React.FC<
  IProtocolValueActionUpdateAmountDeductedAtModalProps
> = ({ protocolValue }) => {
  const { header, message } = getHeaderAndMessage(protocolValue);

  return (
    <Modal
      header={header}
      message={message}
      cancelButtonContent="Okay"
      confirmButtonContent={false}
      onConfirm={(): null => null}
      children={
        <ActionComponent
          {...updateAmountDeductedAtActionComponentProps}
        />
      }
    />
  );
};

export default ProtocolValueActionUpdateAmountDeductedAtModal;

import { IEntityActionUpdateDropdownPillProps } from 'labstep-web/components/Entity/Action/Update/DropdownPill/types';
import { MetadataType } from 'labstep-web/models/metadata/types';

export const DEFAULT_DATETIME_OPTIONS: IEntityActionUpdateDropdownPillProps['items'] =
  [
    {
      label: 'The time specified',
      body: { minutes_before: 0 },
    },
    {
      label: '5 minutes before',
      body: { minutes_before: 5 },
    },
    {
      label: '10 minutes before',
      body: { minutes_before: 10 },
    },
    {
      label: '15 minutes before',
      body: { minutes_before: 15 },
    },
    {
      label: '30 minutes before',
      body: { minutes_before: 30 },
    },
    {
      label: '1 hour before',
      body: { minutes_before: 60 },
    },
    {
      label: '2 hours before',
      body: { minutes_before: 120 },
    },
    {
      label: '1 day before',
      body: { minutes_before: 60 * 24 },
    },
    {
      label: '2 days before',
      body: { minutes_before: 60 * 24 * 2 },
    },
  ];

const DAY_BEFORE_AT_TWELVE_IN_MINUTES = 12 * 60;

const WEEK_BEFORE_AT_TWELVE_IN_MINUTES = 12 * 60 + 7 * 24 * 60;

export const DEFAULT_DATE_OPTIONS: IEntityActionUpdateDropdownPillProps['items'] =
  [
    {
      label: 'The date specified',
      body: { minutes_before: 0 },
    },
    {
      label: '1 day before',
      body: { minutes_before: DAY_BEFORE_AT_TWELVE_IN_MINUTES },
    },
    {
      label: '1 week before',
      body: { minutes_before: WEEK_BEFORE_AT_TWELVE_IN_MINUTES },
    },
  ];

export const convertMinutesBeforeToLabel = (
  minutesBefore: number,
  type: MetadataType,
): string => {
  const options =
    type === MetadataType.date
      ? DEFAULT_DATE_OPTIONS
      : DEFAULT_DATETIME_OPTIONS;
  const option = options.find(
    (o) => o.body.minutes_before === minutesBefore,
  );
  if (option) {
    return option.label;
  }
  return `${minutesBefore} minutes before`;
};
